

/**
 * Enumeration with a definition of custom types meant to be used
 * by the UI to render components that fit the choosen data type.
 * 
 * e.g TextBoxes that only alow the input of numbers in case of
 * AcceptedValueType.int
 */
export const AcceptedValueType = {
    NotMapped: 0,
    int: 1,
    bool: 2,
    String: 3,
    Email: 4,
    Model: function (modelDescription, acceptedModelType, title, inline) {
        return new AcceptedValueTypeModel(modelDescription, acceptedModelType, title, inline);
    },
    Options: function (options) {
        return new AcceptedValueTypeOptions(options);
    },
    MultiOptions: function (options, models) {
        return new AcceptedValueTypeMultpleOptions(options, models);
    }
}

export const AcceptedModelType = {
    Form: 0,
    Table: 1,
    Tab: 2
}

export class AcceptedValueTypeModel {
    constructor(modelDescription, acceptedModelType, title, inline) {
        this.acceptedModelType = acceptedModelType;
        this.modelDescription = modelDescription;
        this.title = title;
        this.inline = inline;
    }
};

export class AcceptedValueTypeOptions {
    constructor(options) {
        this.options = options;
    }
};

export class AcceptedValueTypeMultpleOptions {
    constructor(options, models) {
        this.options = options;
        this.models = models;
    }
};

/**
 * Generate an array of empty values from the given input
 * @param {*} modelDescription A set of AcceptedValueTypes
 * @returns an array of empty values
 * @example getDefaultValue({name: 'number', type: columnType.int},
    {name: 'name', type: columnType.String}) -> [0,'']
 */
export function getDefaultValues(modelDescription) {
    let aux = {};
    Object.keys(modelDescription).forEach(key => {
        switch (modelDescription[key]) {
            case AcceptedValueType.int:
            case AcceptedValueType.bool:
                aux[key] = 0;
                break;
            case AcceptedValueType.String:
            case AcceptedValueType.Email:
                aux[key] = '';
                break;
            case AcceptedValueType.NotMapped:
                //model values defined as not mapped can not be edited not directly created
                aux[key] = null;
                break;
            default:
                if (modelDescription[key] instanceof AcceptedValueTypeModel) {
                    switch (modelDescription[key].acceptedModelType) {
                        case AcceptedModelType.Form: break;
                        case AcceptedModelType.Tab: 
                        let auxTemp = getDefaultValues(modelDescription[key].modelDescription.modelDescription)

                        for(var k in auxTemp) aux[k]=auxTemp[k];

                        
                        
                        break;
                        case AcceptedModelType.Table: aux[key] = []; break;
                    }
                }
                else if (modelDescription[key] instanceof AcceptedValueTypeOptions) {
                }
            //   aux[key] = [];
        };

    })
    return aux;
}
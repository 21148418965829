import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
//Bootstrap
import Container from 'react-bootstrap/Container'
import NavbarTop from '../NavbarTop';

// App Components
import Footer from '../components/Footer';
import { useScreenState } from '../ScreenStateContext';
import { useTheme } from '../ThemeContext';

export default function GradientPage(props) {
  const { t, i18n } = useTranslation();
  const { screenState } = useScreenState();
  const theme = useTheme();

  return (
    <React.Fragment>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Container fluid 
          className={props.pageConfig.class + ' ' +(screenState.device === 'mobile' ? 'mobilecss' : 'desktopcss')}
          >
          <NavbarTop pageTitle={t(props.pageConfig.name)}/>
          {props.children}
          <Footer />
        </Container>
      </div>
    </React.Fragment>
  );
}


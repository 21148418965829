import { AcceptedValueType} from '../../ValueTypes'

const columnsDescription = [

  // { name: 'orgprevEntrepreneur', type: columnType.Text },
  // { name: 'orgprevTrabDesig', type: columnType.Text },
  // { name: 'orgprevServOwn', type: columnType.Text },
  // { name: 'orgprevPooled', type: columnType.Text },
  // { name: 'orgprevSpa', type: columnType.Text },
  // { name: 'orgprevSpaWhich', type: columnType.Text },
  // { name: 'orgprevSpaMedicine', type: columnType.Text },
  // { name: 'orgprevSpaMutual', type: columnType.Text },
]

const condOrgPrev = {
  0: 'companyOptions::condOrgPrev::safety',
  1: 'companyOptions::condOrgPrev::hygiene',
  2: 'companyOptions::condOrgPrev::ergonomics',
  3: 'companyOptions::condOrgPrev::medicineT'
}


const modelEntrepreneur = {
  title:"model::fields::orgprevEntrepreneur",
  modelDescription: {
    orgprevEntrepreneurSafety: AcceptedValueType.int,
    orgprevEntrepreneurHygiene: AcceptedValueType.int,
    orgprevEntrepreneurErgonomy: AcceptedValueType.int,
    null: AcceptedValueType.NotMapped
  }

}
const modelTrabDesing = {
  title:"model::fields::orgprevTrabDesig",
  modelDescription: {
    orgprevTrabDesigSafety: AcceptedValueType.int,
    orgprevTrabDesigHygiene: AcceptedValueType.int,
    orgprevTrabDesigErgonomy: AcceptedValueType.int,
    null: AcceptedValueType.NotMapped
  }

}
const modelServOwn = {
  title:"model::fields::orgprevServOwn",
  modelDescription: {
    orgprevServOwnSafety: AcceptedValueType.int,
    orgprevServOwnHygiene: AcceptedValueType.int,
    orgprevServOwnErgonomy: AcceptedValueType.int,
    orgprevServOwnMedicine: AcceptedValueType.int,
  }

}
const modelPooled = {
  title:"model::fields::orgprevPooled",
  modelDescription: {
    orgprevSpaSafety: AcceptedValueType.int,
    orgprevSpaHygiene: AcceptedValueType.int,
    orgprevSpaErgonomy: AcceptedValueType.int,
    orgprevSpaMedicine: AcceptedValueType.int,
  }

}
const modelSpa = {
  title:"model::fields::orgprevSpa",
  modelDescription: {
    orgprevPooledSafety: AcceptedValueType.int,
    orgprevPooledHygiene: AcceptedValueType.int,
    orgprevPooledErgonomy: AcceptedValueType.int,
    orgprevPooledMedicine: AcceptedValueType.int,
  }

}

const modelDescription = {
  orgprev: AcceptedValueType.MultiOptions(condOrgPrev, [modelEntrepreneur, modelTrabDesing,modelServOwn,modelPooled,modelSpa]),
  // orgprevEntrepreneur: AcceptedValueType.Options(condOrgPrev),
  // orgprevTrabDesig: AcceptedValueType.Options(condOrgPrev),
  // orgprevServOwn: AcceptedValueType.Options(condOrgPrev),
  // orgprevPooled: AcceptedValueType.Options(condOrgPrev),
  // orgprevSpa: AcceptedValueType.Options(condOrgPrev),
  orgprevSpaWhich: AcceptedValueType.String,
  orgprevSpaMedicineName: AcceptedValueType.String,
  orgprevSpaMutual: AcceptedValueType.String,
}






var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import React from 'react';
import FetchService from '../FetchService';
import i18next from "i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { ListItemSecondaryAction, ListItemText, TextField, ListItem, List, IconButton, CircularProgress } from '@material-ui/core';

import { ValueTypes, getDefaultValues, AcceptedValueType, AcceptedValueTypeModel, AcceptedModelType, AcceptedValueTypeOptions, AcceptedValueTypeMultpleOptions } from './ValueTypes';

import CustomTextBox from './CustomTextBox'
import CustomComboBox from './CustomComboBox'

export default function CutstomTableBox(props) {

    const [selectedToId, setSelectedToId] = React.useState({});

    const t = i18next.t.bind(i18next);

    function addRelation(relation) {
        if (!props.value.some(rel => rel[props.model.searchBarField] === relation[props.model.searchBarField])) {
            let aux = props.value;
            aux.push(relation);
            props.stateChangeHandler(props.name, aux);
        }
    }
    function removeRelation(relation) {
        props.stateChangeHandler(props.name, props.value.filter(rel => rel !== relation));
    }


    //searchbar statates
    const [text, setText] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        FetchService.simplePost(props.model.apiRoutes.LIST,
            {},
            (data) => {
                if (active) {
                    setOptions(data);
                }
            }
        )

        return () => { active = false };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    // const containerStyle = {
    // borderStyle: 'solid',
    // borderRadius: '3px',
    // borderWidth: '1px',
    // color: 'lightgray',
    // padding: '10px',
    // margin: '16px'
    //}

    const generateForm = (name, acceptedValue, value, props) => {

        switch (acceptedValue) {
            case AcceptedValueType.NotMapped:
                //can not be edited in the front so it is not displayed
                break;
            case AcceptedValueType.String:
                return (<CustomTextBox
                    name={name}
                    value={value ? value : ""}
                    bootstrapColumSize={12}
                    inputType={'text'}
                    handleChange={props.stateChangeHandler} />);
            case AcceptedValueType.int:
                return (<CustomTextBox
                    name={name}
                    value={value ? value : ""}
                    bootstrapColumSize={12}
                    inputType={'number'}
                    handleChange={props.stateChangeHandler} />);
        }
    }

    return (
        <div className={'form-group col-' + props.bootstrapColumSize}>
            <Row>
                <Col>
                    <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
                        {t('model::fields::' + props.name)}
                    </label>
                </Col>
            </Row>
            <Row>
                {props.value && props.value.length > 0 ? props.value.map((item, index) => {
                    return (
                        <Row>
                            {Object.entries(item).map(([key, value], index2) => {
                                return (
                                    <React.Fragment>
                                        {props.model.modelDescription[key] ? <Col>{generateForm(props.name+'['+index+'].'+key, props.model.modelDescription[key], value, props)} </Col> : ""}
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                    )
                }) : (
                    <Row>
                        {Object.entries(props.model.modelDescription).map(([key, value], index) => {
                            return (
                                <React.Fragment>
                                    <Col>{generateForm(key, value, "", props)} </Col>
                                </React.Fragment>
                            )
                        })}
                    </Row>
                )}
            </Row>
        </div>
    )
}
import { columnType } from '../../Columns';
import { AcceptedValueType } from '../../ValueTypes'

const columnsDescription = [
  { name:'cmrKnowledge',type: columnType.Text },
  { name:'cmrCubstitution',type: columnType.Text },
  { name:'cmrSubstituted1',type: columnType.Text },
  { name:'cmrSubstitute1',type: columnType.Text },
  { name:'cmrSubstituted2',type: columnType.Text },
  { name:'cmrSubstitute2',type: columnType.Text },
  { name:'cmrSubstituted3',type: columnType.Text },
  { name:'cmrSubstitute3',type: columnType.Text },
  { name:'cmrPreventionMeasures',type: columnType.Text },
  { name:'cmrListWorkers',type: columnType.Text },

  { name:'cmrConditions',type: columnType.Text },
  { name:'cmrConditionsDescription',type: columnType.Text },
  { name:'cmrIndividual',type: columnType.Text },
  { name:'cmrHygienic',type: columnType.Text },
  { name:'cmrClothes',type: columnType.Text },
  { name:'cmrWashing',type: columnType.Text },
  { name:'cmrTraining',type: columnType.Text },
]

const modelDescription = {
  cmrKnowledge: AcceptedValueType.bool,
  cmrCubstitution: AcceptedValueType.bool,
  cmrSubstituted1: AcceptedValueType.String,
  cmrSubstitute1: AcceptedValueType.String,
  cmrSubstituted2: AcceptedValueType.String,
  cmrSubstitute2: AcceptedValueType.String,
  cmrSubstituted3: AcceptedValueType.String,
  cmrSubstitute3: AcceptedValueType.String,
  cmrPreventionMeasures: AcceptedValueType.bool,
  cmrListWorkers: AcceptedValueType.bool,
  cmrConditions: AcceptedValueType.bool,
  cmrConditionsDescription: AcceptedValueType.String,
  cmrIndividual: AcceptedValueType.bool,
  cmrHygienic: AcceptedValueType.bool,
  cmrClothes: AcceptedValueType.bool,
  cmrWashing: AcceptedValueType.bool,
  cmrTraining: AcceptedValueType.bool,
}

var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import React, { useEffect } from 'react';

export const ThemeContext  = React.createContext();

/** Holds and provide design context
 */
export default function ThemeProvider(props) {
    //this is a bit unecesary, because you wont be able to change the them(at least in
    //this version)
    const [themeState, setThemeState] = React.useState(props.theme);


    return(
        <ThemeContext.Provider value={{themeState}}>
            {props.children}
        </ThemeContext.Provider>
    )
}

/** Custom hook to access the theme
 */
export function useTheme() {
    return React.useContext(ThemeContext).themeState;
}


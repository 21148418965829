import { columnType } from '../../Columns'
import { AcceptedValueType, AcceptedModelType } from '../../ValueTypes'
import { masterConfig as CompaniesWorkplace1Descr } from './CompaniesWorkplace1Descr'
import { masterConfig as CompaniesWorkplace2Descr } from './CompaniesWorkplace2Descr'
import { masterConfig as CompaniesWorkplace3Descr } from './CompaniesWorkplace3Descr'



const columnsDescription = [
  { name: 'totalEmployees', type: columnType.Text },
  { name: 'totalSelfEmployees', type: columnType.Text },
  { name: 'hasEmployeesHandicapped', type: columnType.Text },
  { name: 'hasEmployeesUnderAge', type: columnType.Text },
  { name: 'hasEmployeesTemporary', type: columnType.Text },
  { name: 'hasEmployeesTrainee', type: columnType.Text },
  { name: 'hasEmployeesPregnant', type: columnType.Text },
]


const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::sometimes'
}
const modelDescription = {
  totalEmployees: AcceptedValueType.int,
  totalSelfEmployees: AcceptedValueType.int,
  hasEmployeesHandicapped: AcceptedValueType.Options(options),
  hasEmployeesUnderAge: AcceptedValueType.Options(options),
  hasEmployeesTemporary: AcceptedValueType.Options(options),
  hasEmployeesTrainee: AcceptedValueType.Options(options),
  hasEmployeesPregnant: AcceptedValueType.Options(options),
  //companyWorkplaces: AcceptedValueType.Model(CompaniesWorkplaceDescr, AcceptedModelType.Table, "", true)
  companyWorkplace1: AcceptedValueType.Model(CompaniesWorkplace1Descr, AcceptedModelType.Form, "model::fields::companyWorkplace1", true),
  companyWorkplace2: AcceptedValueType.Model(CompaniesWorkplace2Descr, AcceptedModelType.Form, "model::fields::companyWorkplace2", true),
  companyWorkplace3: AcceptedValueType.Model(CompaniesWorkplace3Descr, AcceptedModelType.Form, "model::fields::companyWorkplace3", true),
  // nameWorkplace1: AcceptedValueType.String,
  // totalEmployeesWorkplace1: AcceptedValueType.int,
  // totalFemaleEmployeesWorkplace1: AcceptedValueType.int,
  // totalEmployeesSeniority0To5Workplace1: AcceptedValueType.int,
  // totalEmployeesSeniority5To10Workplace1: AcceptedValueType.int,
  // totalEmployeesSeniority10To20Workplace1: AcceptedValueType.int,
  // totalEmployeesSeniority20Workplace1: AcceptedValueType.int,
  // nameWorkplace2: AcceptedValueType.String,
  // totalEmployeesWorkplace2: AcceptedValueType.int,
  // totalFemaleEmployeesWorkplace2: AcceptedValueType.int,
  // totalEmployeesSeniority0To5Workplace2: AcceptedValueType.int,
  // totalEmployeesSeniority5To10Workplace2: AcceptedValueType.int,
  // totalEmployeesSeniority10To20Workplace2: AcceptedValueType.int,
  // totalEmployeesSeniority20Workplace2: AcceptedValueType.int,
  // nameWorkplace3: AcceptedValueType.String,
  // totalEmployeesWorkplace3: AcceptedValueType.int,
  // totalFemaleEmployeesWorkplace3: AcceptedValueType.int,
  // totalEmployeesSeniority0To5Workplace3: AcceptedValueType.int,
  // totalEmployeesSeniority5To10Workplace3: AcceptedValueType.int,
  // totalEmployeesSeniority10To20Workplace3: AcceptedValueType.int,
  // totalEmployeesSeniority20Workplace3: AcceptedValueType.int
}



var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
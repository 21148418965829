import React, { useState, useCallback } from 'react';
import i18next from "i18next";
import { ValueTypes, getDefaultValues, AcceptedValueType, AcceptedValueTypeModel, AcceptedModelType, AcceptedValueTypeOptions, AcceptedValueTypeMultpleOptions } from './ValueTypes';
import { Form, Tab, Tabs, Modal, Row, Button, Col, Container, Accordion, Nav } from "react-bootstrap";
// App Components
import uuid from "uuid";
import FetchService from '../FetchService';

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { ListItemSecondaryAction, ListItemText, TextField, ListItem, List, IconButton, CircularProgress } from '@material-ui/core';

import CustomTextBox from './CustomTextBox'
import CustomComboBox from './CustomComboBox'
import CustomRelationsBox from './CustomRelationsBox'
import CutstomTableBox from './CutstomTableBox'



const t = i18next.t.bind(i18next);
export default function EntityModal(props) {

    const [state, setState] = useState(props.dataToEdit || getDefaultValues(props.modelDescription));

    // to store tabs dynamically
    var globalTabs = [];

    const handleSubmit = (event) => {
        event.preventDefault();
        props.handleSubmit(state);
        props.handleClose();
    }

    const handleAddTab = (title, modelDescription) => {
        let newID = uuid();
        const newTabObject = {
            id: newID,
            name: `${t(title)}`,
            content: generateTabContent(modelDescription)
        };
        globalTabs.push(newTabObject);
    };

    const generateTabContent = (modelDescription) => {
        return (<div>
            <Row>
                {Object.entries(modelDescription).map(([key, acceptedValue], index) => (
                    <React.Fragment key={key}>
                        {/* {generateForm(key, acceptedValue)} */}
                        {generateForm(key, acceptedValue, props)}
                    </React.Fragment>
                ))}
            </Row>
        </div>);
    }

    const renderTabs = () => {
        // Fancy horizontal tabs
        return (

            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {globalTabs.map(function (tab, index) {
                                return (
                                    <Nav.Item className="w-100 p-0 mb-1">
                                        <Nav.Link eventKey={index}>{tab.name}</Nav.Link>
                                    </Nav.Item>);
                            })}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>

                            {globalTabs.map(function (tab, index) {
                                return (
                                    <Tab.Pane eventKey={index}>
                                        {tab.content}
                                    </Tab.Pane>
                                );
                            })}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>);

        // "Classic" tabs
        // return (
        //     <Tabs defaultActiveKey="0" id="detail-tabs">
        //         {globalTabs.map(function (tab, index) {
        //             return (<Tab key={tab.id} eventKey={index} title={tab.name}>
        //                 {tab.content}
        //             </Tab>);
        //         })}
        //     </Tabs>
        // );
    }

    const generateForm = (name, acceptedValue, props) => {

        switch (acceptedValue) {
            case AcceptedValueType.NotMapped:
                //can not be edited in the front so it is not displayed
                break;
            case AcceptedValueType.String:
                return (<CustomTextBox
                    name={name}
                    value={state[name]}
                    bootstrapColumSize={12}
                    inputType={'text'}
                    handleChange={handleChange} />);
            case AcceptedValueType.int:
                return (<CustomTextBox
                    name={name}
                    value={state[name]}
                    bootstrapColumSize={12}
                    inputType={'number'} 
                    handleChange={handleChange} />);

            case AcceptedValueType.Email:
                return (<CustomTextBox
                    name={name}
                    value={state[name]}
                    bootstrapColumSize={12}
                    inputType={'email'} 
                    handleChange={handleChange} />);

            case AcceptedValueType.bool:
                return (<CustomComboBox
                    name={name}
                    value={state[name]} 
                    handleChange={handleChange} />);

            default:    
                if (acceptedValue instanceof AcceptedValueTypeModel) {
                    switch (acceptedValue.acceptedModelType) {
                        case AcceptedModelType.Form:
                            return (
                                <div className='form-group col-12'>
                                    <h3>{t(acceptedValue.title)}</h3>
                                    <Row>
                                        {Object.entries(acceptedValue.modelDescription.modelDescription).map(([key, acceptedValue], index) => (
                                            <React.Fragment key={key}>
                                                {generateForm(key, acceptedValue)}
                                            </React.Fragment>
                                        ))}
                                    </Row></div>);
                        case AcceptedModelType.Tab:
                            handleAddTab(acceptedValue.title, acceptedValue.modelDescription.modelDescription);
                            break;
                        case AcceptedModelType.Table:
                            return(
                                (acceptedValue.inline ? 
                                    <CutstomTableBox
                                value={state[name]}
                                model={acceptedValue.modelDescription}
                                name={name}
                                bootstrapColumSize={12}
                                stateChangeHandler={handleChange}
                                />
                                    : 
                                <CustomRelationsBox
                                value={state[name]}
                                model={acceptedValue.modelDescription}
                                name={name}
                                bootstrapColumSize={12}
                                stateChangeHandler={stateUpdate}
                                />)
                            )
                            break;
                    }
                } else if (acceptedValue instanceof AcceptedValueTypeOptions) {
                    return (
                        <div className='form-group col-12'>
                            <div className='col-12'>
                                <label className='form-control-label ' htmlFor={'input-edit-' + name}>
                                    {t('model::fields::' + name)}
                                </label>
                            </div>
                            <div key={'inline-radio'} className="col-12">
                                <Form.Group controlId={`options-${name}`}>
                                    {Object.entries(acceptedValue.options).map(([key, value], index) => (
                                        <Form.Check
                                            inline
                                            name={name}
                                            value={key}
                                            label={t(value)}
                                            checked={key == state[name]}
                                            type='radio'
                                            id={`inline-radio-${name}-${index}`}
                                            onChange={handleChange} />
                                    ))}
                                </Form.Group>
                            </div>
                        </div>);

                }
                else if (acceptedValue instanceof AcceptedValueTypeMultpleOptions) {
                    return (

                        <div className='form-group col-12'>
                            <Row>
                                <Col></Col>
                                {Object.entries(acceptedValue.options).map(([key, value], index) => (
                                    <Col>
                                        {t(value)}
                                    </Col>
                                ))}
                            </Row>
                            {Object.entries(acceptedValue.models).map(([key, model], index) => (
                                <Row>
                                    <Col>{t(model.title)}</Col>
                                    {Object.entries(model.modelDescription).map(([modelKey, value], modelIndex) => (
                                        (AcceptedValueType.NotMapped != value ?
                                            <Col>
                                                <Form.Check
                                                    name={modelKey}
                                                    checked={state[modelKey] == 1 ? true : false}
                                                    type='checkbox'
                                                    value={state[modelKey]}
                                                    id={`default-${modelKey}`} 
                                                    onChange={function (event) {
                                                        const { name, value } = event.target;
                                                        setState((state) => ({ ...state, [name]: (event.target.checked?1:0) }));
                                                    }}/>
                                            </Col>
                                            : <Col></Col>)
                                    ))}
                                </Row>
                            ))}

                        </div>
                    )

                }
        }
    }



    function handleChange(event) {
        const { name, value } = event.target;

        let indexOf = name.indexOf('[');
        let indexOf2 = name.indexOf(']');
        let indexOf3 = name.indexOf('.');
        if(indexOf>0){
            let arrayName = name.substr(0, indexOf);
            let arrayIndex = name.substr(indexOf+1, (indexOf2-indexOf-1));
            let arrayProperty = name.substr(indexOf3+1);
            
            let array = state[arrayName];
            array[arrayIndex][arrayProperty]=value;
            
            setState((state) => ({ 
                 ...state, 
                [arrayName]: array 
            }));
        } else {
            setState((state) => ({ ...state, [name]: value }));
        }


        
    }

    function stateUpdate(name,value) {
        setState((state) => ({ ...state, [name]: value }));
    }


    

    return (
        <div role="document">
            <Modal size="xl" show={props.show} onHide={props.handleClose} backdrop="static">
                <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("update::updateOf") + ' ' + t(props.entityName)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* This process model to generate form */}
                        <Row>
                            {Object.entries(props.modelDescription).map(([key, acceptedValue], index) => (
                                <React.Fragment key={key}>
                                    {generateForm(key, acceptedValue, state, props)}
                                </React.Fragment>
                            ))}
                        </Row>
                        {/* In case of tabbed model render tabs */}
                        {renderTabs()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {t('actions::Close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('actions::Save')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

import { columnType } from '../../Columns';
import { AcceptedValueType } from '../../ValueTypes'

const columnsDescription = [
  { name:   'trainingReceived', type: columnType.Text },
  { name: 'trainingScope',type: columnType.Text },
  { name: 'trainingWorkRelatedRisks',type: columnType.Text },
  { name: 'trainingChemicalRisks',type: columnType.Text },
]





const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::sometimes'
}
const modelDescription = {
  trainingReceived: AcceptedValueType.Options(options),
  trainingScope: AcceptedValueType.Options(options),
  trainingWorkRelatedRisks: AcceptedValueType.Options(options),
  trainingChemicalRisks: AcceptedValueType.Options(options)
}



var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import { useScreenState } from '../ScreenStateContext';


export default function AcronymPill(props) {
    const { t, i18n } = useTranslation();
    const { screenState } = useScreenState();
    return (
        <OverlayTrigger placement="bottom"
                        trigger={screenState.device === 'mobile'?"click":"hover"} 
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip>{t(props.fullText)}</Tooltip>} 
                        >
                {!props.showText ?
                <Badge variant={props.type} style={style} 
                    onClick={(e) => e.stopPropagation()}>
                    {t(props.acronym)}
                </Badge>
                :
                <div>
                    <Badge variant={props.type} style={style}>
                        {t(props.acronym)}
                    </Badge> 
                    <div style={{padding: '5px'}}>
                        {t(props.fullText)}
                    </div>
                </div>}
        </OverlayTrigger>
    )
}

const style = {
    fontSize: '1.3em',
    width:'100%',
    maxWidth: '70px',
    height: 'inherit',
    margin: '1px',
    float: 'left',
    clear: 'both'
}
import React from 'react';
import { useTranslation } from 'react-i18next';

import {useScreenState} from '../ScreenStateContext';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import Switch from '@material-ui/core/Switch';

export default function ColumnSelector(props) {
    const [isCollapsed,setIsCollapsed] = React.useState(true);
    const { screenState } = useScreenState();
    const { t } = useTranslation();

    return(
        <React.Fragment>
            <Button variant="outline-primary" onClick={()=>{setIsCollapsed(!isCollapsed)}}> 
                <i className='fas fa-columns'></i>
                &nbsp;&nbsp;{t('actions::ToggleColumns')}
            </Button>
            <Collapse in={!isCollapsed} >
                <Container className="column-selector">
                    <Row>
                    {props.columns.map((column,i) => {
                        //in vertical mobile devices only one column is rendered
                        if(screenState.device === 'mobile' && screenState.isPortrait) {
                            return(
                                <Col xs={12}>
                                    <Switch checked={!column.hidden} 
                                    onChange={(event,checked) => {
                                        let index = props.columns.findIndex(column);
                                        let updatedColumns = props.columns;
                                        updatedColumns[index].hidden = !checked;
                                        props.setColumns(updatedColumns); 
                                    }}
                                    className="column-selector-switch"
                                    />
                                    <p style={{display:'inline-block'}}>{column.text}</p>
                                </Col>
                            )
                        }
                        else //in landscape devices 2 or 3 columns are rendered depending on the viewport size
                        {
                            return(
                                <Col xs={6} xl={4}>
                                    <Switch checked={!column.hidden} 
                                    onChange={(event,checked) => {
                                        const newColumns = props.columns.map((col) => {
                                            if (col == column) {
                                                return {
                                                    ...col,
                                                    hidden: !checked
                                                }
                                            }
                                            else{return col}
                                        })
                                        props.setColumns(newColumns); 
                                    }}
                                    className="column-selector-switch"
                                    />
                                    <p style={{display:'inline-block'}}>{column.text}</p>
                                </Col>
                            ) 
                        }
                    })}
                    </Row>
                </Container>
            </Collapse>
        </React.Fragment>
    )
}
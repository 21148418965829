import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import React from 'react';
import i18next from "i18next"
import { TableButtons } from './TableButtons'
import uuid from "uuid";


const t = i18next.t.bind(i18next);



export const columnType = {
    Text: 0,
    Boolean: 1,
    Templated: 2,
    List: 3,
}

export function buildColumns(columnConfig, isFilterActiveRef, actionsEnabled = true, device, handleAddFilter) {

    var columns = [];

    columnConfig.visibleColumns.forEach(columnDescription => {

        let currentFilter;

        switch (columnDescription.type) {
            case columnType.Text:
                var textCol =
                {
                    dataField: columnDescription.name,
                    text: t('model::fields::' + columnDescription.name),
                    sort: columnDescription.sort,
                    title: (cell) => {return cell;},
                    formatter: (cell) => {
                        if (cell != null && cell.length > 100)
                            return cell.substring(0,100) + '...';
                        else
                            return cell;
                    },
                    hidden: (
                        (columnDescription.hiddenOn =='any') || 
                        (columnDescription.hiddenOn =='mobile' && device === 'mobile') || 
                        (columnDescription.hiddenOn =='desktop' && device === 'desktop') ? true : false),
                    filter: textFilter({
                            placeholder: t('actions::Enter') + ' '
                                + t('model::fields::' + columnDescription.name),
                            className: 'column-filter',
                            getFilter: (filter) => {
                                currentFilter = filter;
                                if(handleAddFilter) {
                                    handleAddFilter(currentFilter);
                                }
                            }
                        })
                }
                columns.push(textCol)
                break;
            case columnType.Boolean:
                const boolCol =
                {
                    dataField: columnDescription.name,
                    text: t('model::fields::' + columnDescription.name),
                    hidden: (
                        (columnDescription.hiddenOn =='any') || 
                        (columnDescription.hiddenOn =='mobile' && device === 'mobile') || 
                        (columnDescription.hiddenOn =='desktop' && device === 'desktop') ? true : false),
                    sort: columnDescription.sort,
                    formatter: (cell) => {
                        let checkBoxValue = t('literals::No');
                        if (cell === 1) checkBoxValue = t('literals::Yes');
                        return checkBoxValue;
                    },
                    filter: selectFilter({
                        options: {
                            0: t('literals::No'),
                            1: t('literals::Yes'),
                        },
                        className: 'column-filter',
                        getFilter: (filter) => {
                            currentFilter = filter;
                            if(handleAddFilter) {
                                handleAddFilter(currentFilter);
                            }
                        }
                    })
                }
                columns.push(boolCol);
                
                break;

            case columnType.List:
                const listCol = {
                    dataField: columnDescription.column,
                    text: t('model::fields::' + columnDescription.name),
                    sort: columnDescription.sort,
                    hidden: (
                        (columnDescription.hiddenOn =='any') || 
                        (columnDescription.hiddenOn =='mobile' && device === 'mobile') || 
                        (columnDescription.hiddenOn =='desktop' && device === 'desktop') ? true : false),
                    formatter: (cell, row) => {
                        return (
                            row[columnDescription.name] && row[columnDescription.name].map(item => (
                                <div key={uuid()} className="col-12">{item.code}</div>))
                        );
                    },
                    filter: textFilter({
                            placeholder: t('actions::Enter') + ' ' + t('model::fields::hazardIndicators'),
                            className: 'column-filter',
                            // getFilter: (filter) => {
                            //     currentFilter = filter;
                            //     handleAddFilter(currentFilter);
                            // }
                        })
                }
                columns.push(listCol);
                break;

            case columnType.Templated:
                columns.push(columnDescription.template(columnDescription.name, handleAddFilter));
                break;

        }

    })

    //define the available action buttons for this row
    const rowActions = (cell, row, rowIndex, formatExtraData) => {

        return (
            <div className="text-left">
                <TableButtons cell={cell}
                    handleEdit={columnConfig.columnActions.edit.handler}
                    handleDelete={columnConfig.columnActions.delete.handler}
                    handleDetails={columnConfig.columnActions.details.handler}
                    editPermissions={columnConfig.columnActions.edit.requiresAuth}
                    detailsPermissions={columnConfig.columnActions.details.requiresAuth}
                    deletePermissions={columnConfig.columnActions.delete.requiresAuth}
                />
            </div>
        );
    }

    //it is posible to do not display the action buttons
    if (actionsEnabled) {
        const idCol =
        {
            dataField: 'id',
            text: t('actions::Actions'),
            sort: false,
            hidden: (device === 'mobile' ? true : false),
            formatter: rowActions,
            headerStyle: (colum, colIndex) => {
                return { width: '20em' };
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.stopPropagation();
                },
            },
        }
        //besides of the normal columns, a last one with action buttons is generated and included
        columns.push(idCol);
    }

    return columns;
}
import { columnType } from '../../Columns';
import { AcceptedValueType, AcceptedModelType } from '../../ValueTypes'
import { crudConfig as ProductsDescr} from '../ProductsDescr'


const columnsDescription = [

  { name: 'prodPpd', type: columnType.Text },
  { name: 'prodPpdDescription', type: columnType.Text },
  { name: 'prodDiaminotoluene', type: columnType.Text },
  { name: 'prodDiaminotolueneDescription', type: columnType.Text },
  { name: 'prodToluene', type: columnType.Text },
  { name: 'prodTolueneDescription', type: columnType.Text },
  { name: 'prodFormaldehyde', type: columnType.Text },
  { name: 'prodFormaldehydeDescription', type: columnType.Text },
  { name: 'prodResorcinol', type: columnType.Text },
  { name: 'prodResorcinolDescription', type: columnType.Text },
  { name: 'prodParaben', type: columnType.Text },
  { name: 'prodParabenDescription', type: columnType.Text },
  { name: 'prodTioglycolic', type: columnType.Text },
  { name: 'prodTioglycolicDescription', type: columnType.Text },
  { name: 'prodAmonia', type: columnType.Text },
  { name: 'prodAmoniaDescription', type: columnType.Text },
  { name: 'prodOxygenatedWater', type: columnType.Text },
  { name: 'prodOxygenatedWaterDescription', type: columnType.Text },
  { name: 'prodMaterialNickel', type: columnType.Text },
  { name: 'prodMaterialNickelDescription', type: columnType.Text },
]

const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::unknown',
}


const modelDescription = {
  prodPpd: AcceptedValueType.Options(options),
  prodPpdDescription: AcceptedValueType.String,
  prodDiaminotoluene: AcceptedValueType.Options(options),
  prodDiaminotolueneDescription: AcceptedValueType.String,
  prodToluene: AcceptedValueType.Options(options),
  prodTolueneDescription: AcceptedValueType.String,
  prodFormaldehyde: AcceptedValueType.Options(options),
  prodFormaldehydeDescription: AcceptedValueType.String,
  prodPpd: AcceptedValueType.Options(options),
  prodPpdDescription: AcceptedValueType.String,
  prodResorcinol: AcceptedValueType.Options(options),
  prodResorcinolDescription: AcceptedValueType.String,
  prodParaben: AcceptedValueType.Options(options),
  prodParabenDescription: AcceptedValueType.String,
  prodTioglycolic: AcceptedValueType.Options(options),
  prodTioglycolicDescription: AcceptedValueType.String,
  prodAmonia: AcceptedValueType.Options(options),
  prodAmoniaDescription: AcceptedValueType.String,
  prodOxygenatedWater: AcceptedValueType.Options(options),
  prodOxygenatedWaterDescription: AcceptedValueType.String,
  prodMaterialNickel: AcceptedValueType.Options(options),
  prodMaterialNickelDescription: AcceptedValueType.String,
  products: AcceptedValueType.Model(ProductsDescr, AcceptedModelType.Table)
}

var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import apiRoutes from './apiRoutes';

class AuthService {
  login(credentials) {
    return axios.post(apiRoutes.USER.LOGIN, credentials);
  }

  setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  getUserToken() {
    const userInfo = this.getUserInfo();
    const userToken = userInfo ? userInfo.token : null;
    return userToken;
  }

  getDecodedUserToken() {
    const userInfo = this.getUserInfo();
    const decodedUserToken = userInfo && userInfo.token ? jwtDecode(userInfo.token) : null;
    return decodedUserToken;
  }

  getAuthHeader() {
    return { Authorization: 'Bearer ' + this.getUserToken() };
    //return { headers: { Authorization: 'Bearer ' + this.getUserToken() } };
  }

  getTokenCreationTime() {
    const decodedUserToken = this.getDecodedUserToken();
    const expirationTime = decodedUserToken && decodedUserToken.iat ? decodedUserToken.iat * 1000 : null;
    return expirationTime;
  }

  getTokenExpirationTime() {
    const decodedUserToken = this.getDecodedUserToken();
    const expirationTime = decodedUserToken && decodedUserToken.exp ? decodedUserToken.exp * 1000 : null;
    return expirationTime;
  }

  getTokenRemainingTime() {
    const tokenExpirationTime = this.getTokenExpirationTime();
    const now = new Date();
    const remainingTime = tokenExpirationTime - now;
    return remainingTime > 0 ? remainingTime : 0;
  }

  getTokenRemainingTimeString() {
    const remainingTime = this.getTokenRemainingTime();

    let remainingTimeString = Math.floor(remainingTime / 60000) + 'm';
    remainingTimeString = remainingTimeString + ' ' + Math.floor((remainingTime % 60000) / 1000) + 's';
    return remainingTimeString;
  }

  isTokenExpired() {
    const tokenExpirationTime = this.getTokenExpirationTime();
    const now = new Date();
    return tokenExpirationTime - now < 0 ? true : false;
  }

  logOut() {
    localStorage.removeItem('userInfo');
    //return axios.post(window.CONFIG.SERVER_BASE_URL + apiRoutes.USER.LOGOUT, {}, this.getAuthHeader());
  }
}

export default new AuthService();

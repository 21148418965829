import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// App Components
import HeaderInfoWidgets from '../components/HeaderInfoWidgets';
import Footer from '../components/Footer';
import NavbarTop from '../NavbarTop';
import { useAuthState } from '../AuthStateContext'
import * as IngredientsDescr from '../masters/descriptions/IngredientsDescr'
import CrudPage from '../masters/CrudPage';



export default function Dashboard(props) {
  const { authState, _login, _logout } = useAuthState();
  const { t, i18n } = useTranslation();
  return (
    <div className="main-content">
      <NavbarTop/>
      {authState.isAuth ? <HeaderInfoWidgets /> : <div className="header bg-gradient-default pb-6 pt-5 pt-md-8"></div>}
      <div className="container-fluid mt--7">
        {authState.isAuth || <DashboardContainerNotLogged />}
        {authState.isAuth ? (
        <React.Fragment>
          <CrudPage crudConfig={IngredientsDescr.crudConfig} printTitle={true}/>
        </React.Fragment>):""}
        <Footer />
      </div>
    </div>
  );
}

function DashboardContainerNotLogged() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header bg-transparent">
              <h1 className="mb-0 pt-4 display-3 text-center text-primary">{t('Welcome')}</h1>
              <div className="lead text-gray text-center">
                <strong>Esta   aplicación   permite   efectuar   consultas   relativas   a   los   ingredientes
presentes en los cosméticos</strong>
              </div>
            </div>
            <div className="card-body text-center">
              <Link className="btn btn-primary btn-lg" to="/login" style={{ marginRight: '0' }}>
                {t('dashboard::Sign in Digitalizacion del Mapa de Riesgo Quimico')}
              </Link>
              <div className="mt-5">
                <img
                  alt="Image placeholder"
                  src="/assets/img/imagenmapariesgoinicio.png"
                  style={{ maxWidth: '100%', width: '600px' }}
                />
              </div>
            </div>
            <div className="card-footer text-center">
              <Link className="" to="/about">
                {t('dashboard::More info')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { colorPillTemplate } from './IngredientsDescr';
import {getDefaultValues} from '../ValueTypes';
import FetchService from '../../FetchService';

export function BeautyIngredientDocument(props) {
    const { t } = useTranslation();
    const [state, setState] = React.useState(getDefaultValues(props.modelDescription));
    //to control render element before content is loaded
    const [hasLoaded, setHasLoaded] = React.useState(false);

    React.useEffect(() => {
        if (props.id) {
            setHasLoaded(false);
         
                FetchService.simpleFetch(props.apiRoutes.DETAIL + '/' + props.id, (data) => {
                    let newState = state;
                    Object.entries(data).forEach(([key, value]) => {
                        newState[key] = value;     
                    });
                    setState(newState);
                    setHasLoaded(true);
                });
            
        }
    }, [props.id]);

    return(
        <div role="document">
            <Modal size="xl" show={props.show} onHide={props.handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("BeautyDocs::title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <DocRow name={t("model::fields::glosary")}>
                            <span>{state.glosary ? <b>{state.glosary}</b> : '-'}</span>
                        </DocRow>
                        <DocRow name={t("model::fields::ncas")}>
                            <span>{state.ncas ? state.ncas : '-'}</span>
                        </DocRow>
                        <DocRow name={t("model::fields::risks")}>
                            {colorPillTemplate(state, true)}
                        </DocRow>
                        <DocRow name={t("model::fields::hazardIndicators")}>
                            {Object.keys(state.hazardIndicators).length > 0 ?
                            <Container>
                                {state.hazardIndicators.map((indicator, index) => {
                                    return <React.Fragment key={index}>
                                        <Row>
                                            <p><b>{indicator.code + ": "}</b> {indicator.description}</p>
                                        </Row>  
                                    </React.Fragment>
                                })}                        
                            </Container> 
                            : null }
                        </DocRow>
                        <DocRow name={t('model::fields::annex2')}>
                            {state.annex2 ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::annex2")}</p>
                                
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::annex3')}>
                            {state.annex3 ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::annex3")}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::observationsAnnex')}>
                            {state.observationsAnnex ?
                            <React.Fragment> 
                                <p>{state.observationsAnnex}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::warnings')}>
                        {state.warnings ?
                            <React.Fragment> 
                                <p>{state.warnings}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::aromaticAmine')}>
                            {state.aromaticAmine ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::aromaticAmine")}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::vla')}>
                            {state.vla ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::vla")}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::endocrineDisruptor')}>
                        {state.endocrineDisruptor ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::endocrineDisruptor")}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::allergenPerfume')}>
                            {state.allergenPerfume ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::allergenPerfume")}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('model::fields::sccp')}>
                            {state.sccp ?
                            <React.Fragment> 
                                <p>{t("BeautyDocs::sccp") + state.sccpCode +": "+ state.sccp}</p>
                            </React.Fragment>
                             : null}
                        </DocRow>
                        <DocRow name={t('BeautyDocs::otherNames')}>
                            {state.chemicalNameDCI?<p>{state.chemicalNameDCI}</p>:null}
                            {state.chemicalNameDCI2?<p>{state.chemicalNameDCI2}</p>:null}
                            {state.chemicalNameDCI3?<p>{state.chemicalNameDCI3}</p>:null}
                            {state.chemicalNameDCI4?<p>{state.chemicalNameDCI4}</p>:null}
                            {state.chemicalNameDCI5?<p>{state.chemicalNameDCI5}</p>:null}
                            {state.chemicalNameDCI6?<p>{state.chemicalNameDCI6}</p>:null}
                            {state.chemicalNameDCI7?<p>{state.chemicalNameDCI7}</p>:null}
                            {state.chemicalNameDCI8?<p>{state.chemicalNameDCI8}</p>:null}
                        </DocRow>
                        {(state.hazardIndicators.length>0 || state.annex2 || state.annex3 || state.observationsAnnex 
                        || state.warnings || state.aromaticAmine || state.vla || state.aromaticAmine || state.allergenPerfume || state.sccp ) ?
                        <h3>{t("BeautyDocs::sources")}</h3> : null}
                        {state.hazardIndicators.length>0 ?
                        <Row>
                            <p>{t("BeautyDocs::links1")}</p>
                            <a href='https://www.boe.es/doue/2008/353/L00001-01355.pdf'>
                                https://www.boe.es/doue/2008/353/L00001-01355.pdf
                            </a><br/>
                            <a href='https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=celex:32008R1272'>
                                https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=celex:32008R1272
                            </a><br/><br/>
                        </Row> : null}
                        {(state.annex2 || state.annex3 || state.observationsAnnex || state.warnings) ?
                        <Row>
                            <p>{t("BeautyDocs::links2")}</p>
                            <a href="https://www.boe.es/doue/2009/342/L00059-00209.pdf">
                                https://www.boe.es/doue/2009/342/L00059-00209.pdf
                            </a><br/>
                            <a href="https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=CELEX:32009R1223">
                            https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=CELEX:32009R1223
                            </a><br/><br/>
                        </Row> : null}
                        {state.aromaticAmine ?
                        <Row>
                            <p>{t("BeautyDocs::links3")}</p>
                            <a href="https://www.boe.es/buscar/pdf/2006/BOE-A-2006-22169-consolidado.pdf">
                                https://www.boe.es/buscar/pdf/2006/BOE-A-2006-22169-consolidado.pdf
                            </a>
                        </Row> : null}
                        {state.vla ?
                        <Row>
                            <p>{t("BeautyDocs::links4")}</p>
                            <a href="https://www.insst.es/valores-limites-de-exposicion">
                            https://www.insst.es/valores-limites-de-exposicion
                            </a><br/><br/>
                        </Row> : null}
                        {state.endocrineDisruptor ?
                        <Row>
                            <p>{t("BeautyDocs::links5-1")}</p>
                            <a href="https://ec.europa.eu/environment/chemicals/endocrine/pdf/sec_2007_1635.pdf">
                                https://ec.europa.eu/environment/chemicals/endocrine/pdf/sec_2007_1635.pdf
                            </a>
                            <p>{t("BeautyDocs::links5-2")}</p>
                            <a href="https://www.insst.es/valores-limites-de-exposicion">
                                {t("BeautyDocs::link-5.2")}
                            </a><br/><br/>
                        </Row> : null}
                        {state.allergenPerfume ?
                        <Row>
                            <p>{t("BeautyDocs::links6-1")}</p>
                            <a href="http://ec.europa.eu/health/ph_risk/committees/sccp/documents/out93_en.pdf">
                                http://ec.europa.eu/health/ph_risk/committees/sccp/documents/out93_en.pdf
                            </a>
                            <p>{t("BeautyDocs::links6-2")}</p>
                            <a href="https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_o_073.pdf">
                            https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_o_073.pdf
                            </a><br/><br/>
                        </Row> : null}
                        {state.sccp ?
                        <Row>
                            <p>{t("BeautyDocs::links7")}</p>
                            <a href="https://ec.europa.eu/growth/sectors/cosmetics/cosing_en">
                                https://ec.europa.eu/growth/sectors/cosmetics/cosing_en
                            </a>
                        </Row> : null}


                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t('actions::Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


const DocRow = (props) => {
    return (
        <React.Fragment>
            {props.children ? 
                <Row className="form-group col-12">
                    <Col md={4} sm={12} xs={12}>
                        <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
                            {props.name}
                        </label>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                        {props.children}
                    </Col>
                </Row>
            : null }
        </React.Fragment>);
}

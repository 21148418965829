import React from 'react';
//bootstrap-react components
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

// App Components
import MobileSearchBar from './MobileSearchBar'
import EntityTable from './EntityTable';
import EntityModal from './EntityModal';
import DetailModal from './DetailModal'
import FetchService from '../FetchService';
import Alert from 'react-s-alert';

import i18next from "i18next";

import paginationFactory from 'react-bootstrap-table2-paginator';

import { useAuthState } from '../AuthStateContext';

import { useScreenState } from '../ScreenStateContext';

const t = i18next.t.bind(i18next);

export default function CrudPage(props) {

    const { screenState } = useScreenState();

    let mobileSearchInput = React.createRef();

    let mobileSearchCombo = React.createRef();

    //let clearFilter = React.createRef();

    const [columnFilters, setColumnFilters] = React.useState([]);

    const [clearTrigger, toggleClear] = React.useReducer(state => !state, true)
    const clearAllFilters = () => {
        toggleClear()
        setFilters(0)
    }

    //react states
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [isFilterActive, setFilter] = React.useState(false);
    const [isNewFormOpen, setIsNewFormOpen] = React.useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = React.useState(false);
    const [modelUpdates, setModelUpdates] = React.useState(0);
    const [dataList, setDataList] = React.useState();
    const [idDetails, setIdDetails] = React.useState();
    const [page, setPage] = React.useState(1);
    const [sizePerPage, setSizePage] = React.useState(10);
    const [totalSize, setTotalSize] = React.useState(0);
    const [sortField, setSortField] = React.useState(10);
    const [sortOrder, setSortOrder] = React.useState(0);
    const [filters, setFilters] = React.useState(0);

    //react context 
    const { authState } = useAuthState();

    //react effects
    React.useEffect(() => {
        FetchService.simplePost(props.crudConfig.apiRoutes.LIST_COUNT, {
            pagination: {
                limit: sizePerPage,
                offset: (page - 1) * sizePerPage
            },
            filters,
            sort: {
                sortField: sortField,
                sortOrder: sortOrder
            }
        }, (data) => {
            setTotalSize(data.count)
        });
        FetchService.simplePost(props.crudConfig.apiRoutes.LIST, {
            pagination: {
                limit: sizePerPage,
                offset: (page - 1) * sizePerPage
            },
            filters,
            sort: {
                sortField: sortField,
                sortOrder: sortOrder
            }
        }, setDataList);

    }, [modelUpdates,filters]);

    //button click handlers
    const handleNewClick = (event) => {
        setIsNewFormOpen(true);
    }

    const handleDeleteClick = async (event) => {
        const id = event.target.getAttribute('data-id');

        if (window.confirm(t('messages::Are you sure do you want to delete it?'))) {
            FetchService.simpleDelete(props.crudConfig.apiRoutes.DELETE + '/' + id, () => {
                // Show in app a user friendly message.
                Alert.success(t('messages::Item has been removed'));
                // Update last deleted Id to update UI.
                triggerModelUpdate();
            });
        }
    };

    const handleEditClick = (event) => {
        const id = event.target.getAttribute('data-id');
        var fetchedData = {}
        FetchService.simpleFetch(props.crudConfig.apiRoutes.DETAIL + '/' + id, (data) => {
            Object.entries(data).map(([key, value]) => {
                fetchedData = { ...fetchedData, [key]: value };
            })
            console.log(fetchedData);
            setDataToEdit(fetchedData);
            setIsEditFormOpen(true);
        })
    };

    const handleDetailClick = (event) => {
        setIdDetails(event.target.getAttribute('data-id'));
    };


    //edition and creation of entities
    function handleEditFormSubmit(submitedData) {
        console.log(submitedData);
        FetchService.simplePut(props.crudConfig.apiRoutes.EDIT + '/' + submitedData.id, extractIdsFromRelations(submitedData), () => {
            Alert.success(t('messages::Item has been edited'));
            triggerModelUpdate();
        });
    }

    function handleNewFormSubmit(submitedData) {
        FetchService.simplePost(props.crudConfig.apiRoutes.NEW, extractIdsFromRelations(submitedData), () => {
            Alert.success(t('messages::Item has been added'));
            triggerModelUpdate();
        });
    }


    const handleFormClose = (event) => {
        setIsEditFormOpen(false);
        setIsNewFormOpen(false);
        setIdDetails();
    }

    //React elements are updated when a state hook is setted, this modelUpadtes only serves that purpose
    //forcing a reload of the data table and request a list to the database
    //which seems really ineficient, and we might regret it when we want to retive thousands of elements
    //trough internet
    const triggerModelUpdate = () => {
        setModelUpdates(modelUpdates + 1);
    };

    const handleAddFilter = (currentFilter) => {

        var aux = [...columnFilters];
        aux.push(currentFilter);
        setColumnFilters(aux)
    }

    const conditionalRenderDetailModal = () => {
        if (props.crudConfig.columnsConfig.columnActions.details.isActive) {
            return (props.crudConfig.customDetailModal ?
                <props.crudConfig.customDetailModal
                    show={idDetails >= 0}
                    handleClose={handleFormClose}
                    id={idDetails}
                    modelDescription={props.crudConfig.modelDescription}
                    apiRoutes={props.crudConfig.apiRoutes}
                />
                : <DetailModal
                    show={idDetails >= 0}
                    handleClose={handleFormClose}
                    id={idDetails}
                    modelDescription={props.crudConfig.modelDescription}
                    apiRoutes={props.crudConfig.apiRoutes}
                    entityName={props.crudConfig.entityName}
                />)
        }
    }

    function conditionalRenderNewForm() {
        if (isNewFormOpen) {
            return (
                <EntityModal
                    show={isNewFormOpen}
                    handleSubmit={handleNewFormSubmit}
                    handleClose={handleFormClose}
                    modalTitle={props.crudConfig.entityName}
                    modelDescription={props.crudConfig.modelDescription} />
            )
        }
    }
    function conditionalRenderEditForm() {
        if (isEditFormOpen) {
            return (
                <EntityModal
                    dataToEdit={dataToEdit}
                    show={isEditFormOpen}
                    handleSubmit={handleEditFormSubmit}
                    handleClose={handleFormClose}
                    modalTitle={props.crudConfig.entityName}
                    modelDescription={props.crudConfig.modelDescription} />
            )
        }
    }

    //to create the actions column button handlers are needed
    const columnActions = props.crudConfig.columnsConfig.columnActions;
    if (columnActions.edit)
        if (columnActions.edit.requireAuth && authState.isAuth || !columnActions.edit.requireAuth) {
            columnActions.edit["handler"] = handleEditClick;
        }

    if (columnActions.details)
        if (columnActions.details.requireAuth && authState.isAuth || !columnActions.details.requireAuth) {
            columnActions.details["handler"] = handleDetailClick;
        }

    if (columnActions.delete)
        if (columnActions.delete.requireAuth && authState.isAuth || !columnActions.details.requireAuth) {
            columnActions.delete["handler"] = handleDeleteClick;
        }

    const filterHandler = (event) => {
        setFilter(!isFilterActive);
        setFilters(0);
        setColumnFilters([])
    };

    const handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
        const currentIndex = (page - 1) * sizePerPage;

        FetchService.simplePost(props.crudConfig.apiRoutes.LIST_COUNT, {
            pagination: {
                limit: sizePerPage,
                offset: currentIndex
            },
            filters,
            sort: {
                sortField: sortField,
                sortOrder: sortOrder
            }
        }, (data) => {
            setTotalSize(data.count)
        });

        FetchService.simplePost(props.crudConfig.apiRoutes.LIST, {
            pagination: {
                limit: sizePerPage,
                offset: currentIndex
            },
            filters,
            sort: {
                sortField: sortField,
                sortOrder: sortOrder
            }
        }, setDataList);

        setPage(page);
        setSizePage(sizePerPage);
        setSortField(sortField);
        setSortOrder(sortOrder);
        setFilters(filters);

    }

    const handleSearch = () => {

        FetchService.simplePost(props.crudConfig.apiRoutes.LIST_COUNT, {
            pagination: {
                limit: sizePerPage,
                offset: (page - 1) * sizePerPage
            },
            filters: {
                [mobileSearchCombo.current.value]: {
                    filterVal: mobileSearchInput.current.value,
                    filterType: "TEXT",
                    comparator: "LIKE",
                    caseSensitive: false
                }
            }
        }, (data) => {
            setTotalSize(data.count)
        });


        FetchService.simplePost(props.crudConfig.apiRoutes.LIST, {
            pagination: {
                limit: sizePerPage,
                offset: (page - 1) * sizePerPage
            },
            filters: {
                [mobileSearchCombo.current.value]: {
                    filterVal: mobileSearchInput.current.value,
                    filterType: "TEXT",
                    comparator: "LIKE",
                    caseSensitive: false
                }
            }
        }, setDataList);
    }

    const pagination = paginationFactory({
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize,
        showTotal: true,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                {t('pagination::showing')} {from} {t('pagination::from')} {to} {t('pagination::to')} {size} {t('pagination::results')}
            </span>
        )
    });



    return (
        <Row className={screenState.device === 'mobile' ? 'mobileCrud' : ""}>
            <Col>
                <Card variant="shadow">
                    <Card.Header border="0">

                        {props.printTitle ? (
                            <Row>
                                <Col>
                                    <h3 className='mb-0'>{t('list::listOf') + ' ' + t(props.crudConfig.name)}</h3>
                                </Col>
                            </Row>) : ""}

                        {screenState.device === 'mobile' ? (
                            <h3 className='mb-0'>{t(props.crudConfig.name)}</h3>
                        ) : ""}

                        {props.crudConfig.searchBarField && screenState.device === 'mobile' ? (
                            <MobileSearchBar handleSearch={handleSearch} mobileSearchInput={mobileSearchInput} mobileSearchCombo={mobileSearchCombo} modelDescription={props.crudConfig.modelDescription} />
                        ) : ""}

                        {screenState.device != 'mobile' ? (
                            <Col className="text-right">


                                <Button variant="outline-primary" onClick={clearAllFilters}>
                                    <i className='fas fa-eraser'></i>
                                    &nbsp;&nbsp;{t('actions::ClearFilters')}
                                </Button>

                                {false && props.crudConfig.pageButtons.filter.isActive !== false &&
                                    (props.crudConfig.pageButtons.filter.requiresAuth === false ||
                                        authState.isAuth)
                                    ?
                                    <Button variant="outline-primary" onClick={filterHandler}>
                                        {isFilterActive ? <i className='fas fa-toggle-on'></i> : <i className='fas fa-toggle-off'></i>}
                                        &nbsp;&nbsp;{t('actions::Filter')}
                                    </Button>
                                    : null}

                                {props.crudConfig.pageButtons.new.isActive !== false &&
                                    (props.crudConfig.pageButtons.new.requiresAuth === false ||
                                        authState.isAuth)
                                    ?
                                    <Button variant="outline-primary" onClick={handleNewClick}>
                                        <i className='fas fa-plus'></i>&nbsp;&nbsp;
                                        {t('actions::New')}
                                    </Button>
                                    : null}


                                <Button variant="outline-primary" onClick={function () {
                                    FetchService.simplePost(props.crudConfig.apiRoutes.EXPORT, {
                                        pagination: {
                                            limit: null,
                                            offset: null
                                        },
                                        filters,
                                        sort: {
                                            sortField: sortField,
                                            sortOrder: sortOrder
                                        }
                                    }, function (items) {
                                        const replacer = (key, value) => {
                                            if (!value) return '';  // specify how you want to handle null values here
                                            if (value === 1) return "Sí";
                                            return value;
                                        } 
                                        const header = Object.keys(items[0]);

                                        const translatedHeader = [];
                                        Object.keys(items[0]).forEach((key) => {
                                            translatedHeader.push(t('model::fields::' + key));
                                        });

                                        //translate header
                                        //{t('model::fields::' + props.name)}


                                        const csv = [
                                            translatedHeader.join(','), // header row first
                                            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                                        ].map(row => {
                                            return row.replace(/(?:\\[rnt])+/g,' ')
                                        }).join('\r\n')
                                        var data = new Blob(["\uFEFF"+csv], { type: 'text/csv; charset=utf-8' });
                                        var csvURL = window.URL.createObjectURL(data);
                                        var tempLink = document.createElement('a');
                                        tempLink.href = csvURL;
                                        tempLink.setAttribute('download', `${t(props.crudConfig.name)}.csv`);
                                        tempLink.click();
                                    });
                                }}>
                                    <i className='fas fa-file-download'></i>&nbsp;&nbsp;{t('actions::Download')}
                                </Button>
                            </Col>
                        ) : ""}
                    </Card.Header>
                    <Card.Body>
                        {dataList ? <EntityTable
                            columnsConfig={props.crudConfig.columnsConfig}
                            records={dataList}
                            setRecords={setDataList}
                            readOnly={!authState.isAuth}
                            isFilterActive={isFilterActive}
                            pagination={pagination}
                            setIdDetails={setIdDetails}
                            selectColumns
                            handleAddFilter={handleAddFilter}
                            onTableChange={handleTableChange}
                            clearFilters={clearTrigger}
                        /> : ""}
                    </Card.Body>
                </Card>

                {conditionalRenderNewForm()}
                {conditionalRenderEditForm()}
                {conditionalRenderDetailModal()}
            </Col>
        </Row >
    )
}


function extractIdsFromRelations(submittedData) {
    Object.keys(submittedData).forEach((field) => {
        if (Array.isArray(submittedData[field])) { //this check is weak, but by now arrays are considered to be always relations
            submittedData[field] = submittedData[field].map((subField) => subField.id);
        }
    })
    return submittedData;
}
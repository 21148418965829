import { columnType } from '../../Columns'
import { AcceptedValueType } from '../../ValueTypes'


const columnsDescription = [
    {name: 'nameWorkplace1', type: columnType.Text},
    {name: 'totalEmployeesWorkplace1', type: columnType.Text},
    {name: "totalFemaleEmployeesWorkplace1", type: columnType.Text},
    {name: "totalEmployeesSeniority0To5Workplace1", type: columnType.Text},
    {name: "totalEmployeesSeniority5To10Workplace1", type: columnType.Text},
    {name: "totalEmployeesSeniority10To20Workplace1", type: columnType.Text},
    {name: "totalEmployeesSeniority20Workplace1", type: columnType.Text},
]

  const modelDescription = {
    nameWorkplace1: AcceptedValueType.String,
    totalEmployeesWorkplace1: AcceptedValueType.int,
    totalFemaleEmployeesWorkplace1: AcceptedValueType.int,
    totalEmployeesSeniority0To5Workplace1: AcceptedValueType.int,
    totalEmployeesSeniority5To10Workplace1: AcceptedValueType.int,
    totalEmployeesSeniority10To20Workplace1: AcceptedValueType.int,
    totalEmployeesSeniority20Workplace1: AcceptedValueType.int,
  }

var masterConfig = {
    entityName: "model::entities::companyWorkplace",
    columnsConfig: 
    {
        visibleColumns: columnsDescription,
        columnActions: 
        {
            edit : {isActive: true},
            details: {isActive: true},
            delete: {isActive: true},
        }
    },
    modelDescription: modelDescription,
}

export {masterConfig};
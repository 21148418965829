import { columnType } from '../../Columns';
import { AcceptedValueType } from '../../ValueTypes'

const columnsDescription = [
  { name: 'washing', type: columnType.Text },
  { name: 'tint', type: columnType.Text },
  { name: 'moulding', type: columnType.Text },
  { name: 'conditioners', type: columnType.Text },
  { name: 'treatments', type: columnType.Text },
  { name: 'shaving', type: columnType.Text },
  { name: 'nails', type: columnType.Text },
  { name: 'waxDepilation', type: columnType.Text },
  { name: 'tattos', type: columnType.Text },
  { name: 'laser', type: columnType.Text },
  { name: 'cleaning', type: columnType.Text },
  { name: 'other', type: columnType.Text },
]


const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::sometimes'
}
const modelDescription = {
  
  washing: AcceptedValueType.Options(options),
  tint: AcceptedValueType.Options(options),
  moulding: AcceptedValueType.Options(options),
  conditioners: AcceptedValueType.Options(options),
  treatments: AcceptedValueType.Options(options),
  shaving: AcceptedValueType.Options(options),
  nails: AcceptedValueType.Options(options),
  waxDepilation: AcceptedValueType.Options(options),
  tattos: AcceptedValueType.Options(options),
  laser: AcceptedValueType.Options(options),
  cleaning: AcceptedValueType.Options(options),
  other: AcceptedValueType.String,
  
}



var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'react-s-alert';
import AuthService from '../AuthService';
import FetchService from '../FetchService';
import apiRoutes from '../apiRoutes';
import Card from 'react-bootstrap/esm/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Roles } from '../RolesGuard';


export default function HeaderInfoWidgets(props) {
  const { t, i18n } = useTranslation();
  const [productsCount, setProductsCount] = useState([]);
  const [ingredientsCount, setIngredientsCount] = useState([]);
  const [hazardIndicatorCount, setHazardIndicatorCount] = useState([]);
  const [companiesCount, setCompaniesCount] = useState([]);


  useEffect(() => {
    // FetchService.simpleFetch(apiRoutes.PRODUCT.LIST_COUNT, setProductsCount);
    FetchService.simplePost(apiRoutes.PRODUCT.LIST_COUNT, {}, (data) => {
      setProductsCount({ count: data.count })
    });
  }, []);

  useEffect(() => {
    // FetchService.simpleFetch(apiRoutes.INGREDIENT.LIST_COUNT, setIngredientsCount);
    FetchService.simplePost(apiRoutes.INGREDIENT.LIST_COUNT, {}, (data) => {
      setIngredientsCount({ count: data.count });
    });
  }, []);
  useEffect(() => {
    //FetchService.simpleFetch(apiRoutes.HAZARDINDICATOR.LIST_COUNT, setComponentsCount);
    FetchService.simplePost(apiRoutes.HAZARDINDICATOR.LIST_COUNT, {}, (data) => {
      setHazardIndicatorCount({ count: data.count });
    });

  }, []);

  useEffect(() => {
    //FetchService.simpleFetch(apiRoutes.HAZARDINDICATOR.LIST_COUNT, setComponentsCount);
    FetchService.simplePost(apiRoutes.COMPANY.LIST_COUNT, {}, (data) => {
      setCompaniesCount({ count: data.count });
    });

  }, []);

  return (
    <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          {/* <!-- Card stats --> */}
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::Products')}
                data={productsCount.count}
                iconClass="fas fa-flask"
                bgColorClass="bg-gradient-purple"
                cardLink="/products"
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::Ingredients')}
                data={ingredientsCount.count}
                iconClass="fas fa-puzzle-piece"
                bgColorClass="bg-gradient-info"
                cardLink="/ingredients"
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::HazardIndicators')}
                data={hazardIndicatorCount.count}
                iconClass="fas fa-exclamation-triangle"
                bgColorClass="bg-gradient-warning"
                cardLink="/hazard-indicators"
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <InfoWidget
                title={t('infoWidgets::Companies')}
                data={companiesCount.count}
                iconClass="fas fa-building"
                bgColorClass="bg-gradient-red"
                cardLink="/companies"
              />
            </div>
          </div>
          </div>
        </Container>

    </div>
  );
}

function InfoWidget(props) {
  return (
    <Link to={props.cardLink}>
      <Card>
        <Card.Body>
          <Container>
            <Row>
              <Col xs={8} >
                <h5 className="card-title text-uppercase text-muted mb-0">{props.title}</h5>
                <span className="h2 font-weight-bold mb-0">{props.data}</span>
              </Col>
              <Col xs={4}>
                <div className={'icon icon-shape ' + props.bgColorClass + ' text-white rounded-circle shadow'}>
                  <i className={props.iconClass}></i>
                </div>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Link>
  );
}

import React, { useEffect } from 'react';
import AuthService from './AuthService';
import {isMobile, isTablet, isIOS}  from 'react-device-detect';

export const ScreenStateContext  = React.createContext();

/** Holds and provide screen format context
 */
export default function ScreenStateProvider(props) {
    const [screenState, setScreenState] = React.useState(calculateScreen());
    
    function calculateScreen() {
        let device = isMobile ? (!isTablet ? 'mobile' :'desktop') : 'desktop';
        let isPortrait;

        // On iOS window.screen.orientation.angle is not supported, if fails try deprecated alternative
        if(!isIOS) {
            isPortrait = (device === 'mobile') ?  (window.screen.orientation.angle == 0 ? true : false) : false;
        } else {
            isPortrait = (device === 'mobile') ?  (window.orientation.angle == 0 ? true : false) : false;
        }
        


                
        return {device: device, isPortrait: isPortrait};
    } 

    //the component subscribes to the browser resize event, but updates the screen
    //state with a small delay in order to avoid reloading the DOM too many times
    //if the user is dragging the window
    useEffect(() => {
        let debounceTimeout = null;
        function updateScreenState() {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => setScreenState(calculateScreen()),150);
        }

        window.addEventListener('resize',updateScreenState);

        return() => {
            window.removeEventListener('resize',updateScreenState);
        }
    })

    return(
        <ScreenStateContext.Provider value={{screenState}}>
            {props.children}
        </ScreenStateContext.Provider>
    )
}

/** Custom hook to retrieve the device type and screen orientation
 */
export function useScreenState() {
    return React.useContext(ScreenStateContext);
}


import { columnType } from '../../Columns';
import { AcceptedValueType } from '../../ValueTypes'

const columnsDescription = [


  { name: 'condBuy', type: columnType.Text },
  { name: 'condInstruccions', type: columnType.Text },
  { name: 'condProcedures', type: columnType.Text },
  { name: 'condOpinionChoice', type: columnType.Text },
  { name: 'condHygieneStandards', type: columnType.Text },
  { name: 'condJewels', type: columnType.Text },
  { name: 'condSeparateClothing', type: columnType.Text },
  { name: 'condEat', type: columnType.Text },
  { name: 'condSpecificClothing', type: columnType.Text },
  { name: 'condSterilise', type: columnType.Text },
  { name: 'condSteriliseDescription', type: columnType.Text },
  { name: 'condDoors', type: columnType.Text },
  { name: 'condVentilation', type: columnType.Text },
  { name: 'condCompartmentalisation', type: columnType.Text },
  { name: 'condForcedVentilation', type: columnType.Text },
  { name: 'condForcedVentilationDescription', type: columnType.Text },
  { name: 'condEpi', type: columnType.Text },
  { name: 'condMask', type: columnType.Text },
  { name: 'condMaskType', type: columnType.Text },
  { name: 'condMaskTask', type: columnType.Text },
  { name: 'condGloves', type: columnType.Text },
  { name: 'condGlovesType', type: columnType.Text },
  { name: 'condGlovesTask', type: columnType.Text },
  { name: 'condGlovesReuse', type: columnType.Text },
  { name: 'condGlovesLength', type: columnType.Text },
  { name: 'condGlasses', type: columnType.Text },
  { name: 'condGlassesType', type: columnType.Text },
  { name: 'condGlassesTaks', type: columnType.Text }
]

const options = {
  0: 'literals::No',
  1: 'literals::Yes',
}


const buyOptions = {
  0: 'companyOptions::condBuy::manufacturer',
  1: 'companyOptions::condBuy::stores',
  2: 'companyOptions::condBuy::both'
}


const condAllToNone = {
  0: 'companyOptions::condAllToNone::all',
  1: 'companyOptions::condAllToNone::majority',
  2: 'companyOptions::condAllToNone::some',
  3: 'companyOptions::condAllToNone::none'
}


const modelDescription = {

  condBuy: AcceptedValueType.Options(buyOptions),
  condInstruccions: AcceptedValueType.Options(condAllToNone),
  condProcedures: AcceptedValueType.Options(condAllToNone),
  condOpinionChoice: AcceptedValueType.Options(options),
  condHygieneStandards: AcceptedValueType.Options(options),
  condJewels: AcceptedValueType.Options(options),
  condSeparateClothing: AcceptedValueType.Options(options),
  condEat: AcceptedValueType.Options(options),
  condSpecificClothing: AcceptedValueType.Options(options),
  condSterilise: AcceptedValueType.Options(options),
  condSteriliseDescription: AcceptedValueType.String,
  condDoors: AcceptedValueType.Options(options),
  condVentilation: AcceptedValueType.Options(options),
  condCompartmentalisation: AcceptedValueType.Options(options),
  condForcedVentilationDescription: AcceptedValueType.String,
  condEpi: AcceptedValueType.Options(options),
  condMask: AcceptedValueType.Options(options),
  condMaskType: AcceptedValueType.String,
  condMaskTask: AcceptedValueType.String,
  condGloves: AcceptedValueType.Options(options),
  condGlovesType: AcceptedValueType.String,
  condGlovesTask: AcceptedValueType.String,
  condGlovesReuse: AcceptedValueType.Options(options),
  condGlovesLength: AcceptedValueType.Options(options),
  condGlasses: AcceptedValueType.Options(options),
  condGlassesType: AcceptedValueType.String,
  condGlassesTaks: AcceptedValueType.String
}



var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
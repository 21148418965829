import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector(props) {
  const { t, i18n } = useTranslation();
  return (
    <ul className='navbar-nav align-items-center mr-4 d-none d-md-flex ml-lg-auto'>
      <li className='nav-item dropdown'>
        <a
          className='nav-link pr-0'
          href='#'
          role='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <div className='media align-items-center'>
            <i className='fas fa-globe-asia'></i>
            <div className='media-body ml-2 d-none d-lg-block'>
              <span className='mb-0 text-sm  font-weight-bold'>
                {t('navbarTop::Language')}&nbsp; (<span style={{ textTransform: 'uppercase' }}>{i18n.language}</span>)
              </span>
            </div>
          </div>
        </a>
        <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'>
          <div className=' dropdown-header noti-title'>
            <h6 className='text-overflow m-0'>{t('navbarTop::Select Language')}</h6>
          </div>
          <ul>
            <li>
              <button className='dropdown-item' lang='en' onClick={() => i18n.changeLanguage('en')}>
                <span>{t('languages::English')}</span>
              </button>
            </li>
            <li>
              <button className='dropdown-item' lang='es' onClick={() => i18n.changeLanguage('es')}>
                <span>{t('languages::Spanish')}</span>
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  );
}

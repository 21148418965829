import { columnType } from '../../Columns';
import { AcceptedValueType } from '../../ValueTypes'

const columnsDescription = [

  { name:   'etiPackaging', type: columnType.Text },
  { name: 'etiTransfer',type: columnType.Text },
  { name: 'etiStorage',type: columnType.Text },
  { name: 'etiInfoFds',type: columnType.Text },
  { name: 'etiAvailableFds',type: columnType.Text },

]

const condAllToNone = {
  0: 'companyOptions::condAllToNone::all',
  1: 'companyOptions::condAllToNone::majority',
  2: 'companyOptions::condAllToNone::some',
  3: 'companyOptions::condAllToNone::none'
}

const condAlwaysToNever = {
  0: 'companyOptions::condAlwaysToNever::allways',
  1: 'companyOptions::condAlwaysToNever::majority',
  2: 'companyOptions::condAlwaysToNever::sometimes',
  3: 'companyOptions::condAlwaysToNever::never'
}




const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::sometimes'
}
const modelDescription = {
  
  etiPackaging: AcceptedValueType.Options(condAllToNone),
  etiTransfer: AcceptedValueType.Options(condAllToNone),
  etiStorage: AcceptedValueType.Options(condAlwaysToNever),
  etiInfoFds: AcceptedValueType.Options(options),
  etiAvailableFds: AcceptedValueType.Options(condAllToNone),  
}



var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
import { columnType } from '../../Columns'
import { AcceptedValueType, AcceptedModelType } from '../../ValueTypes'



const columnsDescription = [
    {name: 'name', type: columnType.Text},
    {name: 'cif', type: columnType.Text},
    {name: 'cnae', type: columnType.Text},
    {name: 'male', type: columnType.Boolean},
    {name: 'canine', type: columnType.Boolean},
]

  const modelDescription = {
    name: AcceptedValueType.String,
    cif: AcceptedValueType.String,
    cnae: AcceptedValueType.int,
    address: AcceptedValueType.String,
    phone: AcceptedValueType.int,
    fax: AcceptedValueType.int,
    contactPerson: AcceptedValueType.String,
    email: AcceptedValueType.Email,
    numberOfWorkplaces: AcceptedValueType.int,
    visited: AcceptedValueType.bool,
    female: AcceptedValueType.bool,
    male: AcceptedValueType.bool,
    mixed: AcceptedValueType.bool,
    canine: AcceptedValueType.bool,
    manicurePedicure: AcceptedValueType.bool,
    tattooCenter: AcceptedValueType.bool,
    trainingCentre: AcceptedValueType.bool,
    otherTreatments: AcceptedValueType.bool,
    otherTreatmentsDescription: AcceptedValueType.String,
  }

var masterConfig = {
    entityName: "companies",
    columnsConfig: 
    {
        visibleColumns: columnsDescription,
        columnActions: 
        {
            edit : {isActive: true},
            details: {isActive: true},
            delete: {isActive: true},
        }
    },
    modelDescription: modelDescription,
}

export {masterConfig};
import React from 'react';

import CrudPage from '../CrudPage';
import GradientPage from '../../pages/GradientPage';
import * as MasterMind from '../../MasterMind';
import { columnType } from '../Columns';
import { AcceptedValueType, AcceptedModelType } from '../ValueTypes'
import { crudConfig as IngredientsDescr } from './IngredientsDescr';

const columnsDescription = [
  { name: 'name', type: columnType.Text, sort: true },
  { name: 'brand', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
  { name: 'origin', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
  { name: 'use', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
  { name: 'fds', type: columnType.Boolean, sort: true, hiddenOn: 'mobile' },
  { name: 'classification1', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
  { name: 'classification2', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
  { name: 'language', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
]

const modelDescription = {
  name: AcceptedValueType.String,
  brand: AcceptedValueType.String,
  origin: AcceptedValueType.String,
  use: AcceptedValueType.String,
  fds: AcceptedValueType.bool,
  classification1: AcceptedValueType.String,
  classification2: AcceptedValueType.String,
  language: AcceptedValueType.String,
  ingredients: AcceptedValueType.Model(IngredientsDescr, AcceptedModelType.Table), // relation to table
}

const apiRoutes = {
  NEW: '/v1/product/new',
  LIST: '/v1/product/list',
  LIST_COUNT: '/v1/product/list-count',
  DETAIL: '/v1/product/detail',
  EDIT: '/v1/product/edit',
  DELETE: '/v1/product/delete',
  EXPORT: '/v1/product/export'
}

export var crudConfig = {
  entityName: "model::entities::product",
  pageButtons:
  {
    new: {}, //by default considered requireAuth === true, isActive===true
    filter: {},
  },
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
  apiRoutes: apiRoutes,
  searchBarField: 'name',
  name: "menu::Products",
}

export var pageConfig = {
  name: "menu::Products",
  icon: "fas fa-flask text-primary",
  group: "products",
  path: "/products",
  class: 'bg-gradient-purple',
  requiresAuth: true,
  targetDevice: 'any',
}

// MasterMind.join(
//   <GradientPage pageConfig={pageConfig}>
//     <CrudPage crudConfig={crudConfig} />
//   </GradientPage>,
//   pageConfig
// );


import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
//Bootstrap
import Container from 'react-bootstrap/Container'

import Fab from '@material-ui/core/Fab';


// App Components
import Footer from '../components/Footer';
import { useScreenState } from '../ScreenStateContext';
import { useTheme } from '../ThemeContext';

export default function FloatingBtnPage(props) {
  const { screenState } = useScreenState();
  const theme = useTheme();

  const desktopcss = {
    padding: '50px 20px',
    background: theme.base1,
  }

  const mobilecss = {
    padding: '4px',
    background: theme.base1,
  }

  const accentedPrimaryBtn = {
    background: theme.accent,
    position: 'fixed',
    bottom: '15%',
    right: '10%',
    zIndex: 100,
    color: theme.backGround,
  }

  return (
    <React.Fragment>
      <Fab style={accentedPrimaryBtn} component={Link} to={props.to}>
        {props.FABIcon}
      </Fab>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Container fluid
          style={screenState.device === 'mobile' ? mobilecss : desktopcss}>
          {props.children}
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
}


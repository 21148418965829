import apiRoutes from './apiRoutes';
import AuthService from './AuthService';
import Alert from 'react-s-alert';
import axios from 'axios';

class FetchService {
  // Fetch with a React function to set the state of a component.
  // Use only in basic requests to directly assign the value of variables.
  simpleFetch = async (endpoint, setStateFunction) => {

    const fetchOptions = {
      method: 'GET',
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await fetch(endpoint, fetchOptions);
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.message);
      }
      setStateFunction(data);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  // Axios GET with generic callback
  async simpleGet(endpoint, callback) {

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.get(endpoint, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  }

  // Axios POST with body and generic callback
  simplePost = async (endpoint, body, callback) => {

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.post(endpoint, body, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Call callback function with the response object.
      if (callback) callback(response.data);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  // Axios PUT with body and generic callback
  simplePut = async (endpoint, body, callback) => {

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.put(endpoint, body, options);
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      //console.log(response && response.data ? response.data : 'No response.');
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };

  // Axios DELETE with generic callback
  simpleDelete = async (endpoint, callback) => {

    const options = {
      headers: AuthService.getAuthHeader(),
    };

    try {
      const response = await axios.delete(endpoint, options);
      if (response.status !== 204) {
        throw new Error(response.message);
      }
      // Show in console the response data from server.
      //console.log(response && response.data ? response.data : 'No response.');
      // Call callback function with the response object.
      if (callback) callback(response);
    } catch (error) {
      Alert.error('Error: ' + error.message);
    }
  };
}

export default new FetchService();

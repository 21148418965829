import React, { useState, useEffect } from 'react';
import { Form, Tabs, Tab, Modal, Row, Button, Col, Container, Accordion, Nav } from "react-bootstrap";

import EntityTable from './EntityTable'
import { ValueTypes, getDefaultValues, AcceptedValueType, AcceptedValueTypeModel, AcceptedModelType, AcceptedValueTypeOptions, AcceptedValueTypeMultpleOptions } from './ValueTypes';
// App Components
import FetchService from '../FetchService';
import i18next from "i18next";
import uuid from "uuid";



const t = i18next.t.bind(i18next);

export default function DetailModal(props) {

    const [state, setState] = useState(getDefaultValues(props.modelDescription));

    //to control render element before content is loaded
    const [hasLoaded, setHasLoaded] = useState(false);

    // to store tabs dynamically
    var globalTabs = [];

    const BasicDetailLabel = (props) => {
        return (
            <React.Fragment>
                <Row className="form-group col-12">
                    <Col md={4} sm={12} xs={12}>
                        <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
                            {t('model::fields::' + props.name)}
                        </label>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                        <span>{props.value ? props.value : "-"}</span>
                    </Col>
                </Row>
            </React.Fragment>);
    }

    const BoolDetailLabel = (props) => {
        return (
            <React.Fragment>
                <Row className="form-group col-12">
                    <Col md={4} sm={8} xs={8}>
                        <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
                            {t('model::fields::' + props.name)}
                        </label>
                    </Col>
                    <Col md={8} sm={4} xs={4}>
                        <span>
                            {props.value && props.value == 0 ? t("literals::No") : (props.value && props.value == 1?t("literals::Yes"):"-")}
                        </span>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const CollapsedTable = (props) => {
        return (
            <Col xs={12} >
                <Accordion>
                    <Accordion.Toggle as={Button} eventKey="0" block>
                        {t('list::listOf') + ' ' + t(props.title)}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <EntityTable
                            records={props.data}
                            columnsConfig={props.columnsConfig}
                            readonly
                        />
                    </Accordion.Collapse>
                </Accordion>
            </Col>
        )
    }

    const generateForm = (name, acceptedValue) => {
        switch (acceptedValue) {
            case AcceptedValueType.NotMapped:
                break;
            case AcceptedValueType.String:
            case AcceptedValueType.int:
            case AcceptedValueType.Email:
                return (<BasicDetailLabel name={name} value={state[name]} />);
            case AcceptedValueType.bool:
                return (<BoolDetailLabel name={name} value={state[name]} />);
            //only posible case is another model description, have to use default because
            //who needs interfaces, javascrip obviously does not 
            default:
                if (acceptedValue instanceof AcceptedValueTypeModel) {

                    switch (acceptedValue.acceptedModelType) {
                        case AcceptedModelType.Form:
                            return (
                                <div className='form-group col-12'>
                                    <h3>{t(acceptedValue.title)}</h3>
                                    <Row>
                                        {Object.entries(acceptedValue.modelDescription.modelDescription).map(([key, aceptedType], index) => (
                                            <React.Fragment key={key}>
                                                {generateForm(key, aceptedType)}
                                            </React.Fragment>
                                        ))}
                                    </Row></div>
                            );
                        case AcceptedModelType.Table:
                            return (
                                //under default 
                                <CollapsedTable data={state[name]} columnsConfig={acceptedValue.modelDescription.columnsConfig} title={acceptedValue.modelDescription.entityName} />
                            )
                        case AcceptedModelType.Tab:
                            handleAddTab(acceptedValue.title, acceptedValue.modelDescription.modelDescription);
                            break;
                    }
                } else if (acceptedValue instanceof AcceptedValueTypeOptions) {
                    return (<BasicDetailLabel name={name} value={t(acceptedValue.options[state[name]])} />)
                } else if (acceptedValue instanceof AcceptedValueTypeMultpleOptions) {
                    return (

                        <div className='form-group col-12'>
                            <Row>
                                <Col></Col>
                                {Object.entries(acceptedValue.options).map(([key, value], index) => (
                                    <Col>
                                        {t(value)}
                                    </Col>
                                ))}
                            </Row>
                            {Object.entries(acceptedValue.models).map(([key, model], index) => (

                                <Row>
                                    <Col>{t(model.title)}</Col>
                                    {Object.entries(model.modelDescription).map(([modelKey, value], modelIndex) => (
                                        (AcceptedValueType.NotMapped != value ?
                                            <Col>
                                                <Form.Check
                                                    checked={state[modelKey] == 1 ? true : false}
                                                    type='checkbox'
                                                    id={`default-${modelKey}`} />
                                            </Col>
                                            : <Col></Col>)
                                    ))}
                                </Row>
                            ))}


                        </div>
                    )

                }else {
                    return(<CollapsedTable data={state[name]} columnsConfig={acceptedValue.columnsConfig} />);
                }


        }
    }

    const handleAddTab = (title, modelDescription) => {
        let newID = uuid();
        const newTabObject = {
            id: newID,
            name: `${t(title)}`,
            content: generateTabContent(modelDescription)
        };
        globalTabs.push(newTabObject);
    };

    const generateTabContent = (modelDescription) => {
        return (<div>
            <Row>
                {Object.entries(modelDescription).map(([key, aceptedType], index) => (
                    <React.Fragment key={key}>
                        {generateForm(key, aceptedType)}
                    </React.Fragment>
                ))}
            </Row>
        </div>);
    }

    const renderTabs = () => {
        return (

            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {globalTabs.map(function (tab, index) {
                                return (
                                    <Nav.Item className="w-100 p-0 mb-1">
                                        <Nav.Link eventKey={index}>{tab.name}</Nav.Link>
                                    </Nav.Item>);
                            })}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>

                            {globalTabs.map(function (tab, index) {
                                return (
                                    <Tab.Pane eventKey={index}>
                                        {tab.content}
                                    </Tab.Pane>
                                );
                            })}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>);

        // "Classic" tabs
        // return (
        //     <Tabs defaultActiveKey="0" id="detail-tabs">
        //         {globalTabs.map(function (tab, index) {
        //             return (<Tab key={tab.id} eventKey={index} title={tab.name}>
        //                 {tab.content}
        //             </Tab>);
        //         })}
        //     </Tabs>
        // );
    }

    //when idToEdit changes db details are querried
    useEffect(() => {
        if (props.id) {
            setHasLoaded(false);
         
                FetchService.simpleFetch(props.apiRoutes.DETAIL + '/' + props.id, (data) => {
                    setState(data);
                    setHasLoaded(true);
                });
            
        }
    }, [props.id]);

    return hasLoaded ? (
        <React.Fragment>
            <div role="document">
                <Modal size="xl" show={props.show} onHide={props.handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("details::detailsOf") + ' ' + t(props.entityName)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* This process model to generate form */}
                        <Row>
                            {Object.entries(props.modelDescription).map(([key, aceptedType], index) => (
                                <React.Fragment key={key}>
                                    {generateForm(key, aceptedType)}
                                </React.Fragment>
                            ))}
                        </Row>
                        {/* In case of tabbed model render tabs */}
                        {renderTabs()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleClose}>
                            {t('actions::Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    ) : "";
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { VintageBeach } from '../ColorTheme';

import { useTheme } from '../ThemeContext';

export default function MDashboard(props) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const theme = useTheme();

    return (
        <div style={{
            height: '100vh',
            position: 'relative',
            paddingTop: '10%',
            backgroundColor: theme.base3,
            padding: '20px'
        }}>

            <h1 className="mb-0 pt-4 display-3 text-center" style={{color:'white'}}>Base de datos: MAPA DE RIESGO QUIMICO 4</h1>
            <p className="lead text-gray text-center">Esta aplicación permite efectuar consultas relativas a los ingredientes presentes en los cosméticos.</p>
            <div style={{display:'flex',justifyContent:'center',alignContent: 'center'}}>
                <button className='btn' onClick={() => history.push('/ingredients')}>Acceder RQ Mapp</button>
            </div>    
             <div style={{display:'flex',justifyContent:'center',alignContent: 'center'}}>
               <p>---</p>
            </div>  
            <div style={{display:'flex',justifyContent:'center',alignContent: 'center'}}>
                <img
                  alt="Image placeholder"
                  src="/assets/img/imagenmapariesgoinicio.png"
                  style={{ maxWidth: '100%', width: '600px' }}
                />
            </div>
        </div>
    )
}

const welcomeText = {
    textAlign: 'center',
    display: 'block',
    color: VintageBeach.backGround,
    fontFamily: 'Lato',
    fontSize: '3em',
    marginTop: '100px',
}
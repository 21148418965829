import React, { useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useAuthState } from '../AuthStateContext';
import uuid from "uuid";
 
function TableButtons(props){
    const {authState, _login, _logout} = useAuthState();
    let buttons = [];
    //buttons are displayed if active and do no need permisions, or if they need and have permissions
    if(props.handleEdit && (!props.editPermissions || props.editPermissions === authState.isAuth)){
        buttons.push(<EditButton key={uuid()} cell={props.cell} handleEdit={props.handleEdit}/>);
    }
    if(props.handleDetails && (!props.detailsPermissions || props.detailsPermissions === authState.isAuth)){
        buttons.push(<DetailsButton key={uuid()} cell={props.cell} handleDetails={props.handleDetails}/>);
    }
    if(props.handleDelete && (!props.deletePermissions || props.deletePermissions === authState.isAuth)){
        buttons.push(<DeleteButton key={uuid()} cell={props.cell} handleDelete={props.handleDelete}/>);
    }
    return (
        <ButtonGroup key={uuid()} horizontal="true">{buttons}</ButtonGroup>
    );
}

function EditButton(props)
{
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i className="fas fa-pen-alt"></i>&nbsp;
            {t('actions::Edit')}
        </Tooltip>
      );
    const { t, i18n } = useTranslation();
    return(
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 100 }} overlay={renderTooltip} >
            <button
                type="button"
                data-id={props.cell}
                className="btn btn-outline-primary btn-sm"
                data-toggle="modal"
                data-placement="bottom" 
                data-target="#edit-modal"
                onClick={props.handleEdit}
            >
                <i className="fas fa-pen-alt" data-id={props.cell}></i>&nbsp;

            </button>
        </OverlayTrigger>
    )
}

function DetailsButton(props)
{
    const { t, i18n } = useTranslation();
    const buttonsRef = useRef([]);
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i className="fas fa-eye"></i>&nbsp;
            {t('actions::Detail')}
        </Tooltip>
      );
    return(
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 100 }} overlay={renderTooltip} >
        <button
            id={'detail-button-' + props.cell}
            //ref={(ref) => buttonsRef.current.push(ref)}>
            ref={ref => buttonsRef.current[props.cell] = ref}
            type="button"
            data-id={props.cell}
            className="btn btn-outline-primary btn-sm"
            data-toggle="modal"
            data-target="#detail-modal"
            onClick={props.handleDetails}
        >
            <i className="fas fa-eye" data-id={props.cell}></i>&nbsp;
        </button>
        </OverlayTrigger>
    )
}

function DeleteButton(props)
{
    const { t, i18n } = useTranslation();
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i className="fas fa-times-circle"></i>&nbsp;
            {t('actions::Delete')}
        </Tooltip>
      );
      
    return(
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 100 }} overlay={renderTooltip} >
        <button type="button" data-id={props.cell} className="btn btn-outline-danger btn-sm" onClick={props.handleDelete}>
            <i className="fas fa-times-circle" data-id={props.cell}></i>&nbsp;
        </button>
        </OverlayTrigger>
    )
}

export {TableButtons};
import React, { Fragment, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import { useHistory } from 'react-router-dom';
import { post } from 'axios';
import { RCamera } from 'react-camera-media-stream';
import 'react-camera-media-stream/dist/index.css'
import Alert from 'react-s-alert';

import EntityTable from '../masters/EntityTable';

//bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// App Components
import './filepond.css';

import { JsonToTable } from "react-json-to-table";
import i18next from "i18next";
import { useScreenState } from '../ScreenStateContext';
import * as IngredientsDescr from '../masters/descriptions/IngredientsDescr'
import CrudPage from '../masters/CrudPage';

import { Link } from 'react-router-dom';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import Fab from '@material-ui/core/Fab';
import { useTheme } from '../ThemeContext';
import { BeautyIngredientDocument } from '../masters/descriptions/BeautyIngredientDocument';
const t = i18next.t.bind(i18next);

registerPlugin(FilePondPluginFileEncode);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageResize);


export default function ImageReader(props) {
    const [state, setState] = useState();
    const [loading, setLoading] = React.useState(false);
    const [loadingIngredients, setLoadingIngredients] = React.useState(false);
    const [data, setData] = React.useState();
    const [tokens, setTokens] = React.useState('');
    const [idDetails,setIdDetails] = React.useState();
    const [dataTotalSize,setTotalSize] = React.useState(0);

    const history = useHistory();
    let { screenState } = useScreenState();

    function markText(tokens) {
        
    }

    function updateList(data) {
        if (data?.items?.length > 0) {
            setLoadingIngredients(true);
            setTotalSize(data.items.length);
            setData(data.items);
            setTokens(data.tokens);
        } else {
            // alert(t('image-reader::no-data'));
            Alert.error(t('image-reader::no-data'));
            
        }
        setLoading(false);
        setLoadingIngredients(false);
    }

    async function doOCR(img) {
        setData([]);
        setTokens([]);
        setState(null);
        const formData = new FormData();
        formData.append('file',img);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        post('/v1/ocr', formData, config).then(
            response => updateList(response.data)
        ).catch(error => {
            //            alert(t('image-reader::error'));
            Alert.error(t('image-reader::error'));
            setLoading(false);
            setLoadingIngredients(false);
        });
    };

    function dumpDataToCSV(items) {
        const replacer = (key, value) => {
            if (!value) return '';  // specify how you want to handle null values here
            if (value === 1) return "Sí";
            return value;
         } 
        const header = Object.keys(items[0])
        const translatedHeader = [];
        Object.keys(items[0]).forEach((key) => {
            if (key === 'hazardIndicators') return
            translatedHeader.push(t('model::fields::' + key));
        });

        const csv = [
            translatedHeader.join(','), // header row first
            ...items.map(row => header.map(fieldName => {
                if (fieldName==='hazardIndicators') return null
                return JSON.stringify(row[fieldName], replacer)
            }).join(','))
        ].map(row => {
            return row.replace(/(?:\\[rnt])+/g,' ')
        }).join('\r\n')
        var data = new Blob(["\uFEFF"+csv], { type: 'text/csv; charset=utf-8' });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'data.csv');
        tempLink.click();
    }

    const theme = useTheme();

    const accentedPrimaryBtn = {
        background: theme.accent,
        position: 'fixed',
        bottom: '10%',
        right: '10%',
        zIndex: 100,
        color: theme.backGround,
    }

    return (
        <div>
            {idDetails >= 0 ? 
            <BeautyIngredientDocument
                show={idDetails >= 0}
                handleClose={() => setIdDetails(-1)}
                id={idDetails}
                modelDescription={IngredientsDescr.crudConfig.modelDescription}
                apiRoutes={IngredientsDescr.crudConfig.apiRoutes}
            /> : null}
            {screenState.device === 'desktop' ?
            
                //the camera is only rendered if there is no data to display, but in desktop the drag and drop is always
                //visible because there is enough sceen space to show both
                <>
                    <DragNDrop doOCR={doOCR} setState={setState} setLoading={setLoading} setLoadingIngredients={setLoadingIngredients}/>
                </>
                :
                (data == null || data.length == 0 ?
                    <RCamera
                        isConfirm={true}
                        onTakePicture={(data) => {
                            setLoading(true);
                            setLoadingIngredients(false);
                            doOCR(data.split(",")[1]);
                        }}
                        onClose={() => {
                            if (state == null) {
                                history.goBack();
                            }
                        }}
                        isFullScreen={true}
                        maxWidth={1920}
                    /> : null)
            }

            {data == null || data.length == 0 ? null :
                <React.Fragment>
                    <Container>
                        <Row>
                            <h4>
                                Texto extraído:
                            </h4>
                            <p>
                                {tokens.map((token,index) => {
                                    if (token.id) {
                                        return <span key={index} style={{backgroundColor:'#d1f59f'}}> {token.text} </span>
                                    } else {
                                        return token.text
                                    }
                                })}
                            </p>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <h1 style={{fontSize:'3vw', fontFamily:'Lato', margin:'20 px'}}>
                                    {t('image-reader::ingredients-found-1') + dataTotalSize + t('image-reader::ingredients-found-2')}
                                </h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <Button variant="outline-primary" onClick={function() { dumpDataToCSV(data) }}>
                                    <i className='fas fa-file-download'></i>&nbsp;&nbsp;{t('actions::Download')}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <EntityTable
                                selectColumns
                                columnsConfig={IngredientsDescr.crudConfig.columnsConfig}
                                records={data}
                                readOnly
                                isFilterActive={false}
                                setIdDetails={setIdDetails}
                            />
                        </Row>
                    </Container>
                    {screenState.device === 'mobile' ?
                        <Fab style={accentedPrimaryBtn} component={Link} to={'/image-scanner'} onClick={() => setData([])}>
                            <PhotoCameraRoundedIcon />
                        </Fab>
                        :null
                    }
                </React.Fragment>
                }
            {loading ? (
                <div className="loading">
                    <div className='uil-ring-css'>
                        <div></div>
                    </div>
                    {loadingIngredients ? <center className="uil-info">{t('image-reader::loading-ingredients')}</center> : <center className="uil-info">{t('image-reader::loading')}</center>}
                </div>
            ) : ""}
        </div>
    )
}


function DragNDrop(props) {
    let pond = useRef();
    return (
        <Container>
            <Row >
                <Col xs={12}
                    md={{ span: 10, offset: 1 }}>
                    <div className="image-reader">
                        <h2>{t('image-reader::title')}</h2>
                        <p>
                            Arrastre la imagen con la etiqueta a la caja. 
                            Para mejores resultados ajuste al máximo la etiqueta del producto cosmético. 
                            Haciendo click sobre los ingredientes detectados se mostrará información adicional sobre su peligrosidad.
                        </p>
                        <FilePond
                            allowFileEncode={true}
                            acceptedFileTypes="image/jpeg, image/png, image/jpg"
                            allowImageResize={true}
                            imageResizeUpscale={false}
                            imageResizeMode={'contain'}
                            imageResizeTargetWidth={1920}
                            imageResizeTargetHeight={1920}
                            ref={ref => pond = ref}
                            onaddfile={(err, file) => {
                                props.setLoading(true);
                                props.setLoadingIngredients(false);
                                props.doOCR(file.getFileEncodeBase64String());
                            }}
                            onremovefile={(err, file) => {
                                props.setState(null)
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
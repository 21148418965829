import { columnType } from '../../Columns'
import { AcceptedValueType } from '../../ValueTypes'


const columnsDescription = [
    {name: 'nameWorkplace3', type: columnType.Text},
    {name: 'totalEmployeesWorkplace3', type: columnType.Text},
    {name: "totalFemaleEmployeesWorkplace3", type: columnType.Text},
    {name: "totalEmployeesSeniority0To5Workplace3", type: columnType.Text},
    {name: "totalEmployeesSeniority5To10Workplace3", type: columnType.Text},
    {name: "totalEmployeesSeniority10To20Workplace3", type: columnType.Text},
    {name: "totalEmployeesSeniority20Workplace3", type: columnType.Text},
]

  const modelDescription = {
    nameWorkplace3: AcceptedValueType.String,
    totalEmployeesWorkplace3: AcceptedValueType.int,
    totalFemaleEmployeesWorkplace3: AcceptedValueType.int,
    totalEmployeesSeniority0To5Workplace3: AcceptedValueType.int,
    totalEmployeesSeniority5To10Workplace3: AcceptedValueType.int,
    totalEmployeesSeniority10To20Workplace3: AcceptedValueType.int,
    totalEmployeesSeniority20Workplace3: AcceptedValueType.int,
  }

var masterConfig = {
    entityName: "model::entities::companyWorkplace",
    columnsConfig: 
    {
        visibleColumns: columnsDescription,
        columnActions: 
        {
            edit : {isActive: true},
            details: {isActive: true},
            delete: {isActive: true},
        }
    },
    modelDescription: modelDescription,
}

export {masterConfig};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../ThemeContext';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  return (
    <footer className="footer" style={{alignItems:'flex-end', marginTop:'auto', /*background: theme.base1*/}}>
      <div className="row align-items-center justify-content-xl-between">
        <div className="col-xl-6">
          <div className="copyright text-center text-xl-left text-muted">
            © 2022{' '}
            <a
              href="https://www.fundacionctic.org/"
              className="font-weight-bold ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              CTIC
            </a>
          </div>
        </div>
        <div className="col-xl-6">
          <ul className="nav nav-footer justify-content-center justify-content-xl-end">
            <li className="nav-item">
              <a href="https://www.fundacionctic.org/" className="nav-link" target="_blank" rel="noopener noreferrer">
                CTIC - {t('footer::Technological Center')}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.fundacionctic.org/es/sobre-ctic"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('footer::About Us')}
              </a>
            </li>
            <li className="nav-item">
              <Link to={'/biblio'}> Bibliografía</Link>
            </li>
            <li className="nav-item">
              <Link to={'/legal'}> Aviso Legal</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

const apiRoutes = {
  ROOT: '/v1',
  USER: {
    LOGIN: '/v1/user/login',
    LOGOUT: '/v1/user/logout',
  },
  PROJECT: {
    NEW: '/v1/project/new',
    LIST: '/v1/project/list',
    LIST_COUNT: '/v1/project/list-count',
    DETAIL: '/v1/project/detail',
    EDIT: '/v1/project/edit',
    DELETE: '/v1/project/delete',
  },
  ISSUE: {
    NEW: '/v1/issue/new',
    LIST: '/v1/issue/list',
    LIST_COUNT: '/v1/issue/list-count',
    DETAIL: '/v1/issue/detail',
    EDIT: '/v1/issue/edit',
    DELETE: '/v1/issue/delete',
  },
  PRODUCT: {
    NEW: '/v1/product/new',
    LIST: '/v1/product/list',
    LIST_COUNT: '/v1/product/list-count',
    DETAIL: '/v1/product/detail',
    EDIT: '/v1/product/edit',
    DELETE: '/v1/product/delete',
  },
  INGREDIENT: {
    NEW: '/v1/ingredient/new',
    LIST: '/v1/ingredient/list',
    LIST_COUNT: '/v1/ingredient/list-count',
    DETAIL: '/v1/ingredient/detail',
    EDIT: '/v1/ingredient/edit',
    DELETE: '/v1/ingredient/delete',
  },
  HAZARDINDICATOR: {
    NEW: '/v1/hazardIndicator/new',
    LIST: '/v1/hazardIndicator/list',
    LIST_COUNT: '/v1/hazardIndicator/list-count',
    DETAIL: '/v1/hazardIndicator/detail',
    EDIT: '/v1/hazardIndicator/edit',
    DELETE: '/v1/hazardIndicator/delete',
  },
  COMPANY: {
    NEW: '/v1/company/new',
    LIST: '/v1/company/list',
    LIST_COUNT: '/v1/company/list-count',
    DETAIL: '/v1/company/detail',
    EDIT: '/v1/company/edit',
    DELETE: '/v1/company/delete',
  }
};

export default apiRoutes;

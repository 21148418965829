import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from './AuthService';
import { useAuthState } from './AuthStateContext';


export default function NavbarTop(props) {
  const { t} = useTranslation();
  const {authState,_,logout} = useAuthState();

  //I leave this as it is by now, but im 90% sure that token longevity shoud not
  //be monitored in a navbar
  useEffect(() => {
    const decodedUserToken = AuthService.getDecodedUserToken();
    if (!decodedUserToken || !decodedUserToken.username) {
      logout();
    }
    if (AuthService.isTokenExpired()) {
      logout();
    }
  }, []);
  return (
    <nav className='navbar navbar-top navbar-expand-md navbar-dark' id='navbar-main'>
      <div className='container-fluid'>
        {/* <!-- Brand --> */}
        <a className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block' href='#'>
          {props.pageTitle}
        </a>

        {/* <!-- User --> */}
        {authState.isAuth ? (
          <ul className='navbar-nav align-items-center d-none d-md-flex'>
            <li className='nav-item dropdown'>
              <a
                className='nav-link pr-0'
                href='#'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <div className='media align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img alt='Image placeholder' src='/assets/img/icons/common/user.png' />
                  </span>
                  <div className='media-body ml-2 d-none d-lg-block'>
                    <span className='mb-0 text-sm  font-weight-bold'>{props.account}</span>
                  </div>
                </div>
              </a>
              <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'>
                {/* <div className=' dropdown-header noti-title'>
                  <h6 className='text-overflow m-0'>{t('navbarTop::Welcome!')}</h6>
                </div> */}
                {/* <a href='#' className='dropdown-item'>
                  <i className='ni ni-single-02'></i>
                  <span>{t('navbarTop::My profile')}</span>
                </a> */}
                {/* <a href='#' className='dropdown-item'>
                  <i className='ni ni-settings-gear-65'></i>
                  <span>{t('navbarTop::Settings')}</span>
                </a> */}
                {/* <a href='#' className='dropdown-item'>
                  <i className='ni ni-calendar-grid-58'></i>
                  <span>{t('navbarTop::Activity')}</span>
                </a> */}
                {/* <div className='dropdown-divider'></div> */}
                <a href='#' className='dropdown-item' onClick={() => logout()}>
                  <i className='ni ni-user-run'></i>
                  <span>{t('navbarTop::Logout')}</span>
                </a>
              </div>
            </li>
          </ul>
        ) : (
          <ul className='navbar-nav align-items-center d-none d-md-flex'>
            <li className='nav-item'>
              <Link className='nav-link pr-0' to='/login'>
                <div className='media align-items-center'>
                  <i className='fas fa-sign-in-alt'></i>
                  <div className='media-body ml-2 d-none d-lg-block'>
                    <span className='mb-0 text-sm  font-weight-bold' style={{ lineHeight: '36px' }}>
                      {t('navbarTop::Login')}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

import React from 'react';
import { AcceptedValueType } from './ValueTypes'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import i18next from "i18next";

export default function MobileSearchBar(props) {

  const t = i18next.t.bind(i18next);

  return (
    <Row className="mt-3">
      <Col xs={12} className="p-0">
        <div class="input-group">
          <div class="form-outline col-5">
            <input ref={props.mobileSearchInput} type="search" id="mobileSearch" class="form-control" onKeyUp={props.handleSearch} />
          </div>
          <div class="form-outline col-5">
            <select ref={props.mobileSearchCombo} className="browser-default custom-select">
              {Object.entries(props.modelDescription).map(([key, value]) => {
                return (
                  (value === AcceptedValueType.NotMapped ? "" : <option value={key}>{t('model::fields::' + key)}</option>))
              })}
            </select>
          </div>
          <button type="button" class="btn btn-primary" onClick={props.handleSearch}>
            <i class="fas fa-search"></i>
          </button>
        </div>
      </Col>
    </Row>
  );
}

import React from 'react';
import i18next from "i18next";

const t = i18next.t.bind(i18next);

export default function CustomTextBox(props) {


    return (<div className={'form-group col-' + props.bootstrapColumSize}>
        <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
            {t('model::fields::' + props.name)}
        </label>
        <input
            type={props.inputType}
            id={'input-edit-' + props.name}
            className='form-control'
            value={props.value}
            name={props.name}
            hidden={props.name === 'id'}
            onChange={props.handleChange}
        />
    </div>
    )
}
import { columnType } from '../../Columns'
import { AcceptedValueType, AcceptedModelType } from '../../ValueTypes'


const columnsDescription = [

  { name: 'evaAvailable', type: columnType.Text },
  { name: 'evaUsageAqp', type: columnType.Text },
  { name: 'evaIdentiAqp', type: columnType.Text },
  { name: 'evaIdentiAqpDescription', type: columnType.Text },
  { name: 'evaExposition', type: columnType.Text },
  { name: 'evaInhalation', type: columnType.Text },
  { name: 'evaDermic', type: columnType.Text },
  { name: 'evaUpdate', type: columnType.Text },
  { name: 'evaSensitive', type: columnType.Text },
  { name: 'evaConcentrations', type: columnType.Text },
  { name: 'evaConcentrationsDescription', type: columnType.Text },

]


const options = {
  0: 'literals::No',
  1: 'literals::Yes',
  2: 'literals::sometimes'
}

const modelDescription = {
  evaAvailable: AcceptedValueType.Options(options),
  evaUsageAqp: AcceptedValueType.Options(options),
  evaIdentiAqp: AcceptedValueType.Options(options),
  evaIdentiAqpDescription: AcceptedValueType.String,
  evaExposition: AcceptedValueType.Options(options),
  evaInhalation: AcceptedValueType.Options(options),
  evaDermic: AcceptedValueType.Options(options),
  evaUpdate: AcceptedValueType.Options(options),
  evaSensitive: AcceptedValueType.Options(options),
  evaConcentrations: AcceptedValueType.Options(options),
  evaConcentrationsDescription: AcceptedValueType.String,
  
}

var masterConfig = {
  entityName: "companies",
  columnsConfig:
  {
    visibleColumns: columnsDescription,
    columnActions:
    {
      edit: { isActive: true },
      details: { isActive: true },
      delete: { isActive: true },
    }
  },
  modelDescription: modelDescription,
}

export { masterConfig };
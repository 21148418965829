import { columnType } from '../Columns';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Col from 'react-bootstrap/Col'
import { AcceptedValueType, AcceptedModelType } from '../ValueTypes'
import { textFilter } from 'react-bootstrap-table2-filter';
import i18next from "i18next";
import Badge from 'react-bootstrap/Badge'
import { crudConfig as HazardIndicatorsConfig } from './HazardIndicatorsDescr'
import uuid from "uuid";
import AcronymPill from '../../components/AcronymPill';
import {BeautyIngredientDocument} from './BeautyIngredientDocument';

const t = i18next.t.bind(i18next);

export function colorPillTemplate(row, showText = false) {
    let pills = [];
        pills.push(row.hazardIndicators.some(HzrdInd => HzrdInd.risks.cancerigen) ? <AcronymPill key={uuid()} showText={showText} type="danger" acronym='risks::acronyms::carcinogen' fullText='risks::carcinogen'/> : null);
        pills.push(row.hazardIndicators.some(HzrdInd => HzrdInd.risks.mutagenic) ? <AcronymPill key={uuid()} showText={showText} type="danger" acronym='risks::acronyms::mutagenic' fullText='risks::mutagenic'/> : null);
        pills.push(row.hazardIndicators.some(HzrdInd => HzrdInd.risks.reproductionToxic) ? <AcronymPill key={uuid()} showText={showText} type="danger" acronym='risks::acronyms::reproductionToxic' fullText='risks::reproductionToxic'/> : null);
        pills.push(row.endocrineDisruptor != 0 ? <AcronymPill key={uuid()} showText={showText} type="danger" acronym='risks::acronyms::endocrineDisruptor' fullText='risks::endocrineDisruptor'/> : null);
        pills.push(row.hazardIndicators.some(HzrdInd => HzrdInd.risks.dermalSensitive) ? <AcronymPill key={uuid()} showText={showText} type="warning" acronym='risks::acronyms::dermalSensitiser' fullText='risks::dermalSensitiser'/> : null);
        pills.push(row.hazardIndicators.some(HzrdInd => HzrdInd.risks.inhalatorySensitive) ? <AcronymPill key={uuid()} showText={showText} type="warning" acronym='risks::acronyms::inhalationSensitiser' fullText='risks::inhalationSensitiser'/> : null);
        pills.push(row.annex2 ? <AcronymPill key={uuid()} showText={showText} type='forbidden' fullText='risks::forbidden' acronym='risks::acronyms::forbidden'/>:null);
        pills.push(row.annex3 ? <AcronymPill key={uuid()} showText={showText} type='restricted' fullText='risks::restricted' acronym='risks::acronyms::restricted'/>:null);
        pills.push(row.vla ? <AcronymPill key={uuid()} showText={showText} type='info' fullText='risks::vla' acronym='risks::acronyms::vla'/>:null);
        pills.push(row.aromaticAmine ? <AcronymPill key={uuid()} showText={showText} type='info' fullText='risks::aromaticAmine' acronym='risks::acronyms::aromaticAmine'/>:null);
        pills.push(row.allergenPerfume ? <AcronymPill key={uuid()} showText={showText} type='info' fullText='risks::allergenPerfume' acronym='risks::acronyms::allergenPerfume'/>:null);
        return (pills);
}

//name is not used but the api passes the caller name to templates in case the same template is used in
//more than one column
const columnPillTemplate = (name) => {
    return {
        dataField: 'risks',
        sort: false,
        text: t('model::fields::risks'),
        formatter: (cell, row) => {
            return colorPillTemplate(row);
        },
    }
}

const hazardListTemplate = (name, handleAddFilter) => {
    return {
        dataField:'$code',
        sort: false,
        text: t('model::fields::hazardIndicators'),
        formatter: (cell,row) => {
            return (
                row.hazardIndicators && row.hazardIndicators.map((item, index) => {
                    return (<React.Fragment key={index}>
                        <OverlayTrigger placement="bottom" trigger="hover" delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip>{t(item.description)}</Tooltip>} >
                            <Col key={index}>{item.code}</Col>
                        </OverlayTrigger>
                    </React.Fragment>)
                })
            )
        },
        filter: textFilter({
            placeholder: t('actions::Enter') + ' ' + t('model::fields::hazardIndicators'),
            className: 'column-filter',
            getFilter: (filter) => {
                if (handleAddFilter) {
                    handleAddFilter(filter);
                }
            }
        })
    }
}

const columnsDescription = [
    { name: 'glosary', type: columnType.Text, sort: true },
    { name: 'ncas', type: columnType.Text, sort: true,},
    { name: 'warnings', type: columnType.Text , sort:true, hiddenOn: 'mobile' },
    { name: 'annex2', type: columnType.Boolean, sort: true, hiddenOn: 'mobile' },
    { name: 'annex3', type: columnType.Boolean, sort: true, hiddenOn: 'mobile' },
    { name: 'vla', type: columnType.Boolean, sort: true, hiddenOn: 'any' },
    { name: 'observationsAnnex', type: columnType.Boolean, sort: true, hiddenOn: 'any' },
    { name: 'sccp', type: columnType.Text , sort:true, hiddenOn: 'any' },
    { name: 'sccpCode', type: columnType.Text, sort: true, hiddenOn: 'any' },
    { name: 'aromaticAmine', type: columnType.Boolean , sort:true , hiddenOn: 'any' },
    { name: 'endocrineDisruptor', type: columnType.Boolean , sort:true , hiddenOn: 'any' },
    { name: 'endocrineDisruptorType', type: columnType.Boolean , sort:true, hiddenOn: 'any' },
    { name: 'allergenPerfume', type: columnType.Boolean , sort:true, hiddenOn: 'any' },
    { name: 'paraben', type: columnType.Boolean , sort:true, hiddenOn: 'any' },
    { name: 'chemicalNameDCI', type: columnType.Text , sort:true , hiddenOn: 'any' },
    { name: 'chemicalNameDCI2', type: columnType.Text , sort:true , hiddenOn: 'any' },
    { name: 'chemicalNameDCI3', type: columnType.Text , sort:true , hiddenOn: 'any' },
    { name: 'chemicalNameDCI4', type: columnType.Text , sort:true , hiddenOn: 'any' },
    { name: 'chemicalNameDCI5', type: columnType.Text , sort:true , hiddenOn: 'any' },
    { name: 'chemicalNameDCI6', type: columnType.Text , sort:true, hiddenOn: 'any' }, 
    { name: 'chemicalNameDCI7', type: columnType.Text , sort:true, hiddenOn: 'any' },
    { name: 'chemicalNameDCI8', type: columnType.Text , sort:true, hiddenOn: 'any' },
    { name: 'hazardIndicators', type: columnType.Templated, template: hazardListTemplate, sort: false },
    { name: 'risks', type: columnType.Templated, template: columnPillTemplate, sort: false },
]

const modelDescription = {
    glosary: AcceptedValueType.String,
    ncas: AcceptedValueType.String,
    annex2: AcceptedValueType.bool,
    annex3: AcceptedValueType.bool,
    vla: AcceptedValueType.bool,
    observationsAnnex: AcceptedValueType.String,
    warnings: AcceptedValueType.String,
    sccp: AcceptedValueType.String,
    sccpCode: AcceptedValueType.String,
    aromaticAmine: AcceptedValueType.bool,
    endocrineDisruptor: AcceptedValueType.bool,
    endocrineDisruptorType: AcceptedValueType.String,
    allergenPerfume: AcceptedValueType.bool,
    paraben: AcceptedValueType.bool,
    chemicalNameDCI: AcceptedValueType.String,
    chemicalNameDCI2: AcceptedValueType.String,
    chemicalNameDCI3: AcceptedValueType.String,
    chemicalNameDCI4: AcceptedValueType.String,
    chemicalNameDCI5: AcceptedValueType.String,
    chemicalNameDCI6: AcceptedValueType.String,
    chemicalNameDCI7: AcceptedValueType.String,
    chemicalNameDCI8: AcceptedValueType.String,
    risks: AcceptedValueType.NotMapped,
    // hazardIndicators: HazardIndicatorsConfig, // relation to table
    hazardIndicators: AcceptedValueType.Model(HazardIndicatorsConfig, AcceptedModelType.Table), // relation to table
    
}
const apiRoutes = {
    NEW: '/v1/ingredient/new',
    LIST: '/v1/ingredient/list',
    LIST_COUNT: '/v1/ingredient/list-count',
    DETAIL: '/v1/ingredient/detail',
    EDIT: '/v1/ingredient/edit',
    DELETE: '/v1/ingredient/delete',
    EXPORT: '/v1/ingredient/export',
    LIST_BY_IDS: '/v1/ingredient/getByIds',
    COUNY_BY_IDS: '/v1/ingredient/countByIds'
    
}

export var crudConfig = {
    entityName: "model::entities::ingredient",
    pageButtons: 
    {
        new: {}, //by default considered requireAuth === true, isActive===true
        filter:{},
    },
    columnsConfig:
    {
        visibleColumns: columnsDescription,
        columnActions:
        {
            edit: { isActive: true},
            details: { isActive: true},
            delete: { isActive: true},
        }
    },
    customDetailModal: BeautyIngredientDocument,
    modelDescription: modelDescription,
    apiRoutes: apiRoutes,
    searchBarField: 'glosary',
    name: "menu::Ingredients",
}

export const pageConfig = {
    name: "menu::Ingredients",
    icon: "fas fa-puzzle-piece text-primary",
    group: "products",
    path: "/ingredients",
    requiresAuth: false,
    class: 'bg-gradient-info',
    targetDevice: 'desktop',
}
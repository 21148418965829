import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import CrudPage from './masters/CrudPage';
import GradientPage from './pages/GradientPage';
import * as MasterMind from './MasterMind';
import * as ProductsDescr from './masters/descriptions/ProductsDescr'
import * as IngredientsDescr from './masters/descriptions/IngredientsDescr'
import * as HazardIndicatorsDesc from './masters/descriptions/HazardIndicatorsDescr'
import * as CompaniesDescr from './masters/descriptions/CompaniesDescr'
import ImageReader from './image-reader/ImageReader'

import FloatingBtnPage from './pages/FloatingBtnPage'
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

ReactDOM.render(<App />, document.getElementById('root'));


MasterMind.join(
    <GradientPage pageConfig={ProductsDescr.pageConfig}>
        <CrudPage crudConfig={ProductsDescr.crudConfig} />
    </GradientPage>,
    ProductsDescr.pageConfig
);

MasterMind.join(
    <GradientPage pageConfig={IngredientsDescr.pageConfig}>
        <CrudPage crudConfig={IngredientsDescr.crudConfig} />
    </GradientPage>,
    IngredientsDescr.pageConfig
);

MasterMind.join(
    <GradientPage pageConfig={HazardIndicatorsDesc.pageConfig}>
        <CrudPage crudConfig={HazardIndicatorsDesc.crudConfig} />
    </GradientPage>,
    HazardIndicatorsDesc.pageConfig
);

MasterMind.join(
    <GradientPage pageConfig={CompaniesDescr.pageConfig}>
        <CrudPage crudConfig={CompaniesDescr.crudConfig} />
    </GradientPage>,
    CompaniesDescr.pageConfig
);

MasterMind.join(
    <GradientPage pageConfig={ {
        name: "Enlaces y referencias",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/biblio",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'desktop'}
    }>
        <Card>
            <Card.Header>
                <Link to={'/ingredients'}> ← Volver a ingredientes </Link>
            </Card.Header>
            <Card.Body>
                <ul>
                    <li>
                        Reglamento Productos Cosméticos: Reglamento (CE) Nº 1223/2009 del 
                        Parlamento Europeo y del Consejo de 30 de noviembre de 2009 sobre los 
                        productos cosméticos<br/>
                        <a href="https://www.boe.es/doue/2009/342/L00059-00209.pdf">enlace</a><br/>
                        <a href="https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=CELEX:32009R1223">enlace</a><br/>
                    </li>
                    <li>
                        Reglamento CLP: Reglamento (CE) nº 1272/2008 del Parlamento Europeo 
                        y del Consejo de 16 de diciembre de 2008 sobre clasificación, etiquetado 
                        y envasado de sustancias y mezclas, y por el que se modifican y derogan 
                        las directivas 67/548/CEE y 1999/45/CE y se modifica el Reglamento (CE) 
                        nº 1907/2006<br/>
                        <a href="https://www.boe.es/doue/2008/353/L00001-01355.pdf">enlace</a><br/>
                        <a href="https://eur-lex.europa.eu/legal-content/ES/ALL/?uri=celex:32008R1272">enlace</a><br/>
                    </li>
                    <li>
                        CosIng. European Commission database for information on cosmetic 
                        substances and ingredients<br/>
                        <a href="https://ec.europa.eu/growth/sectors/cosmetics/cosing_en">enlace</a><br/>
                    </li>
                    <li>
                        Límites de exposición profesional para agentes químicos en España  <br/>
                        <a href="https://www.insst.es/valores-limites-de-exposicion">enlace</a><br/>
                    </li>
                    <li>
                        Alteradores Endocrinos: COMMISSION STAFF WORKING DOCUMENT on the
                        implementation on the “Community Strategy for Endocrine Disrupters”-a 
                        range of substances suspected of interfering with the hormone system of 
                        humans and wildlife (SEC(2007) 1635)<br/>
                        <a href="https://ec.europa.eu/environment/chemicals/endocrine/pdf/sec_2007_1635.pdf">enlace</a><br/>
                    </li>
                    <li>
                        Alérgenos del perfume: <br/>
                        SCCNFP/0017/98, Fragrance allergy in consumers<br/>
                        <a href="https://ec.europa.eu/health/ph_risk/committees/sccp/documents/out93_en.pdf">enlace</a><br/>
                        SCCS/1459/11, OPINION on Fragrance allergens in cosmetic products<br/>
                        <a href="https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_o_073.pdf">enlace</a><br/>
                    </li>
                    <li>
                        Real Decreto 1299/2006, de 10 de noviembre, por el que se aprueba el 
                        cuadro de enfermedades profesionales en el sistema de la Seguridad 
                        Social y se establecen criterios para su notificación y registro<br/>
                        <a href="https://www.boe.es/buscar/pdf/2006/BOE-A-2006-22169-consolidado.pdf">enlace</a><br/>
                    </li>
                    <li>
                        INSST. Cosméticos<br/>
                        <a href="https://www.insst.es/cosmeticos">enlace</a><br/>
                    </li>
                    <li>
                        Agencia Española de medicamentos y productos sanitarios (AEMPS)<br/>
                        <a href="https://www.aemps.gob.es/">enlace</a><br/>
                    </li>
                    <li>
                        Real Decreto 85/2018, de 23 de febrero, por el que se regulan los productos cosméticos.<br/>
                        <a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2018-2693">enlace</a><br/>
                    </li>                    
                </ul>
            </Card.Body>
        </Card>
    </GradientPage>,
    {
        name: "Enlaces y referencias",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/biblio",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'any'
    }
)

MasterMind.join(
    <GradientPage pageConfig={ {
        name: "Aviso legal",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/legal",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'desktop'}
    }>
        <Card>
            <Card.Header>
                <Link to={'/ingredients'}> ← Volver a ingredientes </Link>
            </Card.Header>
            <Card.Body>
                <p>
                    El Instituto Asturiano de Prevención de Riesgos Laborales (IAPRL), pretende
                    velar   por   la   actualidad   y   exactitud   de   la   información   contenida   en   esta
                    aplicación, no pudiendo garantizar que la totalidad del contenido suministrado
                    esté permanentemente actualizado. El acceso a la aplicación, así como el uso
                    de la misma, son exclusiva responsabilidad del usuario.
                </p>
            </Card.Body>
        </Card>
        
    </GradientPage>,
    {
        name: "Informacion legal",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/legal",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'any'
    }
)


MasterMind.join(
    <GradientPage pageConfig={ {
        name: "Más información",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/about",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'desktop'}
    }>
        <Card>
            <Card.Header>
                <Link to={'/ingredients'}> ← Volver a ingredientes </Link>
            </Card.Header>
            <Card.Body>
                <p>
                  Esta aplicación permite efectuar consultas relativas a los ingredientes 
                  presentes en los cosméticos empleados en los centros de trabajo del sector 
                  de peluquería y otros tratamientos de belleza.  Para cada ingrediente se indica, 
                  su nombre, nº CAS, su presencia o no en los Anexos II y III del Reglamento 1223/2009 
                  sobre los productos cosméticos, si dispone de valor límite ambiental (VLA), si es amina 
                  aromática (AA), alterador endocrino (AE), alérgeno del perfume (AP) y las indicaciones 
                  de peligro (frases H) que le corresponden a la sustancia según el Reglamento CLP. 
                  Además, si existen, también se indican algunas observaciones y advertencias que se 
                  establecen en el Reglamento 1223/2009 y alguna de las opiniones de la Comisión Europea 
                  a través de sus diversos comités: Scientific Committee on Consumer Safety (SCCS), 
                  Scientific Committee on Cosmetic Products (SCCP), Scientific Committee on Cosmetic 
                  Products and Non-Food Products (SCCNFP).
                </p>
                <p>
                  La base de datos en que se fundamenta esta aplicación proviene del  proyecto  
                  “Mapa de Riesgo Químico en Asturias: Sector peluquería y otros tratamientos de belleza”, 
                  realizado por el Instituto Asturiano de Prevención de Riesgos Laborales durante 
                  los años 2014-2016.
                </p>
                <p>
                  <a href="https://www.iaprl.org/especialidades-preventivas/higiene-industrial/proyecto-mapa-de-riesgo-quimico-en-asturias">
                    enlace
                  </a>
                </p>
            </Card.Body>
        </Card>
        
    </GradientPage>,
    {
        name: "Más información",
        icon: "fas fa-puzzle-piece text-primary",
        group: "info",
        path: "/about",
        requiresAuth: false,
        class: 'bg-gradient-info',
        targetDevice: 'any'
    }
)






const imageReaderPageConfig = {
    name: "pages::Image Reader",
    icon: "ni ni-camera-compact text-primary", //yet
    group: "scanner",
    path: "/image-scanner",
    requiresAuth: false,
    class: 'bg-gradient-light',
    targetDevice: 'any',
}

MasterMind.join(
    <GradientPage pageConfig={imageReaderPageConfig}>
        <ImageReader />
    </GradientPage>,
    imageReaderPageConfig
);


// Mobile

// //Asuming that the indregient columns in mobile are going to be the same that those in desktop
MasterMind.join(
    <FloatingBtnPage FABIcon={<PhotoCameraRoundedIcon />} to={'/image-scanner'}>
        <CrudPage crudConfig={IngredientsDescr.crudConfig} />
    </FloatingBtnPage>,
    { ...IngredientsDescr.pageConfig, targetDevice: 'mobile' }
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
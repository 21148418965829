import React from 'react';
import { columnType } from '../Columns';
import { AcceptedValueType } from '../ValueTypes'

const columnsDescription = [
    { name: 'code', type: columnType.Text, sort: true },
    { name: 'description', type: columnType.Text, sort: true },
]

const modelDescription = {
    code: AcceptedValueType.String,
    description: AcceptedValueType.String,
    risk: AcceptedValueType.NotMapped,
}

const apiRoutes = {
    NEW: '/v1/hazardIndicator/new',
    LIST: '/v1/hazardIndicator/list',
    LIST_COUNT: '/v1/hazardIndicator/list-count',
    DETAIL: '/v1/hazardIndicator/detail',
    EDIT: '/v1/hazardIndicator/edit',
    DELETE: '/v1/hazardIndicator/delete',
    EXPORT: '/v1/hazardIndicator/export'
}

export var crudConfig = {
    entityName: "model::entities::hazardIndicator",
    name: "menu::HazardIndicators",

    pageButtons: 
    {
        new: {}, //by default considered requireAuth === true, isActive===true
        filter:{},
        export:{}, //not implemented
    },
    columnsConfig:
    {
        visibleColumns: columnsDescription,
        columnActions:
        {
            edit: { isActive: true },
            details: { isActive: false },
            delete: { isActive: true },
        }
    },
    modelName: "HazardIndicator",
    modelDescription: modelDescription,
    searchBarField: 'code',
    apiRoutes: apiRoutes,
}

export const pageConfig = {
    name: "menu::HazardIndicators",
    icon: 'fas fa-exclamation-triangle text-primary', 
    group: "products",
    path: "/hazard-indicators",
    requiresAuth: false,
    class: 'bg-gradient-warning',
    targetDevice: 'any',
}

// MasterMind.join(
//     <GradientPage pageConfig={pageConfig}>
//         <CrudPage crudConfig={crudConfig} />
//     </GradientPage>,
//     pageConfig
// );

import React from 'react';
import i18next from "i18next";

const t = i18next.t.bind(i18next);

export default function CustomComboBox(props) {

    return (
        <div className='form-group col-4'>
            <label className='form-control-label' htmlFor={'input-edit-' + props.name}>
                {t('model::fields::' + props.name)}
            </label>
            <select
                className="browser-default custom-select"
                onChange={props.handleChange}
                value={props.value}
                name={props.name}>
                <option selected=""></option>
                <option value="1" selected={props.value === 1 ? true : false}>{t('literals::Yes')}</option>
                <option value="0" selected={props.value === 0 ? true : false}>{t('literals::No')}</option>
            </select>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// react-bootstrap-table2
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { buildColumns } from './Columns'
import { useScreenState } from '../ScreenStateContext';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import ColumnSelector from './ColumnSelector';

export default function EntityTable(props) {
    const { t } = useTranslation();
    const { screenState } = useScreenState();
    const [visibility,setVisibility] = useState(true);


    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            props.setIdDetails(row.id);
        }
    };

    const [colState, setColState] = React.useState(buildColumns(props.columnsConfig, props.isFilterActive || false, !props.readOnly, screenState.device, props.handleAddFilter))

    //terrible solution for a terrible problem
    useEffect(() =>{
        setVisibility(false);
        setTimeout(() => setVisibility(true),10);
    },[colState,props.clearFilters])

    return (
        <React.Fragment>
            <div>
                {props.selectColumns && props.records && props.records.length ? (
                    <div>
                        <ColumnSelector columns={colState} setColumns={setColState}/> 
                        <hr /></div>
                ) : ""}
                {visibility ?
                <BootstrapTable
                    remote
                    keyField="id"
                    options={props.tableOptions}
                    data={props.records}
                    columns={colState}
                    caption={props.caption}
                    bootstrap4={true}
                    headerWrapperClasses="thead-light"
                    bordered={false}
                    hover
                    condensed
                    filter={filterFactory()}
                    pagination={props.pagination}
                    onTableChange={props.onTableChange}
                    rowEvents={tableRowEvents}
                    noDataIndication={t('literals::No data')}
                    rowStyle={{paddingLeft:'0.1rem', paddingRight:'0.1rem'}}
                />
                : null}
            </div>
        </React.Fragment>
    );
}
import React, { useEffect } from 'react';
import AuthService from './AuthService';

export const AuthStateContext  = React.createContext();

/** Holds an authentification context and provides account state, login() and logout()
 * functions.
 */
export default function AuthStateProvider(props) {
    const [authState, setAuthState] = React.useState(
        {
          isAuth: false,
          userName: null,
        }
    )
    
    //check if user was already loged
    useEffect(() => {
        const decodedUserToken = AuthService.getDecodedUserToken();
        if (decodedUserToken && decodedUserToken.username) {
            login(decodedUserToken.username);
        }
    },[])
  
    function logout() {
        setAuthState({
            isAuth: false,
            userName: null,
        });
        AuthService.logOut();
    };

    function login(user) {
        setAuthState({
            isAuth: true,
            userName: user,
        });
    }

    return(
        <AuthStateContext.Provider value={{authState,login,logout}}>
            {props.children}
        </AuthStateContext.Provider>
    )
}

/** Custom hook to retrieve the authentication state
 */
export function useAuthState() {
    return React.useContext(AuthStateContext);
}


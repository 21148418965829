import React from 'react';
import { columnType } from '../Columns';
import { AcceptedValueType, AcceptedModelType } from '../ValueTypes'
import { masterConfig as CompaniesEmployeesDescr } from './company/CompaniesEmployeesDescr'
import { masterConfig as CompaniesGeneralDesc } from './company/CompaniesGeneralDesc'
import { masterConfig as CompaniesActivityDesc } from './company/CompaniesActivityDesc'
import { masterConfig as CompaniesConditionsDesc } from './company/CompaniesConditionsDesc'
import { masterConfig as CompaniesLabelingDesc } from './company/CompaniesLabelingDesc'
import { masterConfig as CompaniesRiskEvaluationDescr } from './company/CompaniesRiskEvaluationDescr'
import { masterConfig as CompaniesTrainingDesc } from './company/CompaniesTrainingDesc'
import { masterConfig as CompaniesVigilanceDesc } from './company/CompaniesVigilanceDesc'
import { masterConfig as CompaniesOrgPrevDesc } from './company/CompaniesOrgPrevDesc'
import { masterConfig as CompaniesCMRDesc } from './company/CompaniesCMRDesc'
import { masterConfig as CompaniesProdDesc } from './company/CompaniesProdDesc'


const columnsDescription = [
    { name: 'name', type: columnType.Text, sort: true },
    { name: 'cif', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'address', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'phone', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'fax', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'contactPerson', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'email', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'numberOfWorkplaces', type: columnType.Text, sort: true, hiddenOn: 'mobile' },
    { name: 'totalEmployees', type: columnType.Text, sort: true, hiddenOn: 'mobile' },    
]

const modelDescription = {
    company: AcceptedValueType.Model(CompaniesGeneralDesc, AcceptedModelType.Tab, "sections::companyIdentification"),
    companyEmployees: AcceptedValueType.Model(CompaniesEmployeesDescr, AcceptedModelType.Tab, "sections::companyEmployees"),
    companiesOrgPrevDesc: AcceptedValueType.Model(CompaniesOrgPrevDesc, AcceptedModelType.Tab, "sections::companiesOrgPrev"),
    companyActivity: AcceptedValueType.Model(CompaniesActivityDesc, AcceptedModelType.Tab, "sections::companyActivity"),
    companyConditions: AcceptedValueType.Model(CompaniesConditionsDesc, AcceptedModelType.Tab, "sections::companyConditions"),
    companyLabeling: AcceptedValueType.Model(CompaniesLabelingDesc, AcceptedModelType.Tab, "sections::companyLabeling"),
    companyRiskEvaluation: AcceptedValueType.Model(CompaniesRiskEvaluationDescr, AcceptedModelType.Tab, "sections::companyRiskEvaluation"),
    companiesTraining: AcceptedValueType.Model(CompaniesTrainingDesc, AcceptedModelType.Tab, "sections::companiesTraining"),
    companiesVigilanceDesc: AcceptedValueType.Model(CompaniesVigilanceDesc, AcceptedModelType.Tab, "sections::companiesVigilance"),
    companiesCMRDesc: AcceptedValueType.Model(CompaniesCMRDesc, AcceptedModelType.Tab, "sections::companiesCMR"),
    companiesProd: AcceptedValueType.Model(CompaniesProdDesc, AcceptedModelType.Tab, "sections::companiesProd"),
}

const apiRoutes = {
    NEW: '/v1/company/new',
    LIST: '/v1/company/list',
    LIST_COUNT: '/v1/company/list-count',
    DETAIL: '/v1/company/detail',
    EDIT: '/v1/company/edit',
    DELETE: '/v1/company/delete',
    EXPORT: '/v1/company/export'
}

export var crudConfig = {
    entityName: "model::entities::company",
    pageButtons:
    {
        new: {}, //by default considered requireAuth === true, isActive===true
        filter: {},
    },
    columnsConfig:
    {
        visibleColumns: columnsDescription,
        columnActions:
        {
            edit: { isActive: true },
            details: { isActive: true },
            delete: { isActive: true },
        }
    },
    modelDescription: modelDescription,
    apiRoutes: apiRoutes,
    searchBarField: 'name',
    name: "menu::Companies",
}

export const pageConfig = {
    name: "menu::Companies",
    icon: "fas fa-building text-primary",
    group: "products",
    path: "/companies",
    requiresAuth: true,
    class: 'bg-gradient-red',
    targetDevice: 'any',
}

// MasterMind.join(
//   <GradientPage pageConfig={pageConfig}>
//     <CrudPage crudConfig={crudConfig}/>
//   </GradientPage>,
//   pageConfig
// );

import React, { Suspense } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
// App Components
import NavbarTop from './NavbarTop';
import Dashboard from './dashboard/Dashboard';
/**import Products from './pages/Products';
import Companies from './pages/Companies';
import Ingredients from './pages/Ingredients';
import HazardIndicators from './pages/HazardIndicators';
import QrReader from './qr-reader/QrReader';
import GettingStarted from './documentation/GettingStarted';
import Tutorial from './documentation/Tutorial';
import About from './documentation/About';*/
// import Login from './Login';
// import AuthService from './AuthService';
// import RolesGuard from './RolesGuard';
// import apiRoutes from './apiRoutes';
// import ImageReader from './image-reader/ImageReader';

import {MMRoutes, MMDesktopMenus} from './MasterMind'
import AuthStateProvider, { AuthStateContext, useAuthState } from './AuthStateContext';

// Other Packages
// Images and CSS
import logo from './logo.svg';
import './App.css';
// Configurations
import ScreenStateProvider from './ScreenStateContext';
import ThemeProvider from './ThemeContext';
import { VintageBeach } from './ColorTheme';


// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

export default function App(props) {

    return (
      <Suspense fallback={<Loader/>}>
        <ThemeProvider theme={VintageBeach}>
          <ScreenStateProvider>
            <AuthStateProvider>
              <Router>
                <MMDesktopMenus />

                <MMRoutes/>
                <Alert stack={{ limit: 5 }} position="top-right" effect="slide" html={true} timeout={3000} offset={40} />
              </Router>
          </AuthStateProvider>
        </ScreenStateProvider>
      </ThemeProvider>
      </Suspense>
    );
}






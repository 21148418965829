import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

//engine
import { useAuthState } from './AuthStateContext';
import { useScreenState } from './ScreenStateContext';

//bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

//pages
import Dashboard from './dashboard/Dashboard';
import MDashboard from './dashboard/MDashboard';
import Login from './Login';

import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';

var minions = [];
var groups = [];


/** The page is now managed by the mastermind
 * 
 * @param {*} pageComponent The page component to display
 * @param {string} group Pages with the same group are close in menus 
 * @param {string} path url from the display 
 */
export function join(pageComponent, pageConfig) {
  if (!groups.find(gr => gr === pageConfig.group)) {
    groups.push(pageConfig.group);
  }
  minions.push({ page: pageComponent, config: pageConfig });
}

export function MMRoutes(props) {
  const { authState, _login, _logout } = useAuthState();
  const { screenState } = useScreenState();
  const { t } = useTranslation();
  return (
    <div style={screenState.device === 'desktop' ? page : null}>
      <Route
        exact
        path="/"
        render={() => (
          screenState.device === 'mobile' ? <MDashboard /> : <Dashboard title="Welcome" />
        )}
      />
      <Route
        exact
        path="/login"
        render={() => <Login />}
      />
      {minions.map((minion, index) =>
      //im one condition away of implementing a finite state machine
      {
        const authCheck = !minion.config.requiresAuth || authState.isAuth;
        const deviceCheck = minion.config.targetDevice === screenState.device || minion.config.targetDevice === 'any';

        let a = ((authCheck && deviceCheck) ?
          (<React.Fragment key={index}>
            <Route
              exact
              path={minion.config.path}
              render={() => minion.page}
            />
          </React.Fragment>)
          :
          null)

        return a;
      })}
    </div>
  )
}

export function MMDesktopMenus(props) {
  const { authState, _login, logout } = useAuthState();
  const { screenState } = useScreenState();
  const { t } = useTranslation();

  const renderElements = (groups) => {
    return (
      <div>
        {groups.map((group, index) => (
          <React.Fragment key={index}>
            <Container fluid className="p-0" >
              <hr className="my-3" />
              {/* <Row noGutters>
                <Col xs={12}>
                  <h6 className="navbar-heading text-muted">{group}</h6>
                </Col>
              </Row> */}
              <Row noGutters>
                <Col xs={12}>
                  <ListGroup className="nav">
                    {minions.filter(minion => minion.config.group === group).map((minion, index2) => (
                      //menu items only rendered in the configured auth state
                      minion.config.targetDevice !== 'mobile' &&
                        (!minion.config.requiresAuth || authState.isAuth) ?
                        (<React.Fragment key={index2}>
                          <ListGroup.Item as={Link} action to={minion.config.path} className="nav-link" style={sidenavLink}>
                            <SideMenuButton icon={minion.config.icon} title={minion.config.name} />
                          </ListGroup.Item>
                        </React.Fragment>) : null
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </Container>

          </React.Fragment>
        ))}
      </div>
    )
  }


  return (
    screenState.device === 'desktop' ?
      <Container fluid style={sidenav} className="bg-white">
        <Row noGutters>
          {/* <!-- Brand --> */}
          <Link className="navbar-brand pt-0" to="/">
            <img src="/assets/img/brand/logogobierno.png" className="navbar-brand-img" alt="..." /><br />  
            <img src="/assets/img/brand/logo.png" className="navbar-brand-img" alt="..." />
          </Link>
        </Row>
        {/* <!-- Divider -->  */}
        <hr className="my-3" />
        <Row noGutters>
          <Col xs={12}>
            <Link className=" nav-link" to="/">
              <Container fluid className="p-0" >
                <Row noGutters>
                  <Col xs={2}>
                    <i className="ni ni-tv-2 text-primary" />
                  </Col>
                  <Col xs={10}>
                    {t('menu::Dashboard')}
                  </Col>
                </Row>
              </Container>
            </Link>
          </Col>
        </Row>
        <Row noGutters>
          {renderElements(groups)}
        </Row>
      </Container>
      :
      <Nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white">
        <Container fluid style={mobileNav}>
        {authState.isAuth ? (
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidenav-collapse-main"
            aria-controls="sidenav-main"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ><span className="navbar-toggler-icon"></span>
          </button>):""}
          {/* <!-- Brand --> */}
          <Link className="navbar-brand pt-0" to="/">
            <img src="/assets/img/brand/logogobierno.png" className="navbar-brand-img" alt="..." /><br />  
          </Link>
          <Link className="navbar-brand pt-0" to="/">
            <img src="/assets/img/brand/logo.png" className="navbar-brand-img" alt="..." />
          </Link>
          {/* <!-- Collapse --> */}
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            {/* <!-- Collapse header --> */}
            <div className="d-md-none">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <Link className=" nav-link" to="/">
                    <img src="/assets/img/brand/logogobierno.png" className="navbar-brand-img" alt="..." /><br />  
                    <img src="/assets/img/brand/logo.png" alt="" />
                  </Link>
                </div>
                <div className="col-6 collapse-close">
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#sidenav-collapse-main"
                    aria-controls="sidenav-main"
                    aria-expanded="false"
                    aria-label="Toggle sidenav"
                  >
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="navbar-nav p-1">
                {renderElements(groups)}
            </div>
          </div>
          {/* <!-- User --> */}
          {authState.isAuth ? (

            <ul className="nav align-items-center d-md-none">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="media align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="Image placeholder" src="/assets/img/icons/common/user.png" />
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                  <a href="#" className="dropdown-item" onClick={() => logout()}>
                    <i className="ni ni-user-run"></i>
                    <span>{t('navbarTop::Logout')}</span>
                  </a>
                </div>
              </li>
            </ul>
          ) : (""
            // <ul className="nav align-items-center d-md-none">
            //   <li className="nav-item">
            //     <Link className="nav-link pr-0" to="/login">
            //       <div className="media align-items-center">
            //         <i className="fas fa-sign-in-alt"></i>
            //         <div className="media-body ml-2 d-none d-lg-block">
            //           <span className="mb-0 text-sm  font-weight-bold" style={{ lineHeight: '36px' }}>
            //             {t('navbarTop::Login')}
            //           </span>
            //         </div>
            //       </div>
            //     </Link>
            //   </li>
            // </ul>
          )}
        </Container>
      </Nav>)
}


function SideMenuButton(props) {
  const { t } = useTranslation();
  return (
    <Container fluid className="p-0" >
      <Row noGutters>
        <Col xs={2}>
          <i className={props.icon} />
        </Col>
        <Col xs={10}>
          {t(props.title)}
        </Col>
      </Row>
    </Container>
  )
}

//move this to a css as soon as everything is working
const sidebarwidth = 230;

const sidenav = {
  height: '100%',
  width: sidebarwidth + 'px',
  position: 'fixed',
  zIndex: '1',
  top: '0',
  left: '0',
  overflowX: 'hidden',
  paddingTop: '20px',
}
const sidenavLink = {
  color: '#888',
  backGround: 'transparent',
  fontSize: '0.9rem',
  fontFamily: 'Open Sans, sans-serif',
  border: 0,
  marginLeft: '-10px',
  marginRight: '-10px',
  padding: '15px',
  width: sidebarwidth + 'px'
}

const mobileNav = {
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 1rem'
}

const page = {
  marginLeft: sidebarwidth + 'px', /* Same as the width of the sidebar */
}
